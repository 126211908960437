import { combineReducers } from "@reduxjs/toolkit";
import casesReducer from "../features/cases/casesSlice";
import authReducer from "../features/auth/authSlice";
import caseDetailsReducer from "../features/case-details/caseDetailsSlice"

const rootReducer = combineReducers({
  cases: casesReducer, // Manages the case-related state
  auth: authReducer,   // Manages the authentication state
  caseDetails: caseDetailsReducer, // Manages the case details state
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
import React from "react";
import { Box, Typography, Pagination } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface PaginationControlsProps {
  totalCases: number;
  page: number;
  rowsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const PaginationControls: React.FC<PaginationControlsProps> = ({
  totalCases,
  page,
  rowsPerPage,
  setPage,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: theme.spacing(2),
      }}
    >
      <Typography variant="body2" color={theme.palette.text.secondary}>
        Showing {(page - 1) * rowsPerPage + 1}-
        {Math.min(page * rowsPerPage, totalCases)} results from {totalCases}
      </Typography>
      <Pagination
        count={Math.ceil(totalCases / rowsPerPage)}
        page={page}
        onChange={(event, value) => setPage(value)}
        // Removed the color prop since we'll style the selected item with sx
        sx={{
          "& .MuiPaginationItem-root": {
            "&.Mui-selected": {
              backgroundColor: theme.palette.info.main, // selected page background
              color: theme.palette.common.white, // selected page text color
            },
            "&.Mui-selected:hover": {
              backgroundColor: theme.palette.info.dark,
            },
          },
        }}
      />
    </Box>
  );
};

export default PaginationControls;

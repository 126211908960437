import React from "react";
import {
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const LoadingSkeleton = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        maxWidth: "100%",
        overflowX: "hidden",
      }}
    >
      {/* Top Bar Skeleton */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: theme.spacing(2),
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Skeleton variant="text" width={200} height={50} />
          <Skeleton variant="circular" width={40} height={40} sx={{ ml: 2 }} />
        </Box>
      </Box>

      {/* Filters Skeleton */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: theme.spacing(2),
        }}
      >
        <Box sx={{ display: "flex", gap: theme.spacing(2) }}>
          <Skeleton variant="rectangular" width={100} height={40} />
          <Skeleton variant="rectangular" width={150} height={40} />
          <Skeleton variant="rectangular" width={150} height={40} />
        </Box>
        <Box sx={{ display: "flex", gap: theme.spacing(2) }}>
          <Skeleton variant="rectangular" width={400} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
        </Box>
      </Box>

      {/* Table Skeleton */}
      <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
        <TableContainer component={Paper} sx={{ maxHeight: "100%" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Skeleton variant="text" width={100} height={30} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={100} height={30} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={100} height={30} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={100} height={30} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={100} height={30} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={100} height={30} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={100} height={30} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={100} height={30} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={100} height={30} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={100} height={30} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(5)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={30} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={30} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={30} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={30} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={30} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={30} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={30} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={30} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={30} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={30} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Pagination Skeleton */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: theme.spacing(2),
        }}
      >
        <Skeleton variant="text" width={200} height={30} />
        <Skeleton variant="rectangular" width={300} height={40} />
      </Box>
    </Box>
  );
};

export default LoadingSkeleton;

import React from "react";
import { Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface DeviceChipProps {
  device: string;
}

const DeviceChip: React.FC<DeviceChipProps> = ({ device }) => {
  const theme = useTheme();

  return (
    <Chip
      label={device}
      size="small"
      sx={{
        backgroundColor: "white",
        color: theme.palette.text.primary,
        borderRadius: "8px",
        padding: "0 8px",
        fontSize: "0.75rem",
        border: `1px solid ${theme.palette.grey[300]}`,
        height: "24px",
      }}
    />
  );
};

export default DeviceChip;

import React, { useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import EmailImageSection from "./EmailImageSection";
import InvestigativeStepsTabs from "./InvestigativeStepsTabs";
import ReactFlowSection from "./ReactFlowSection";

interface EmailFlowAndInvestigativeStepsProps {
  emailImageUrl: string;
  caseDetail: any;
  analyses: {
    name: string;
    kind: string;
    conclusion: string;
    summary: string;
    data?: {
      total: number;
      malicious: string[];
      benign: string[];
      inconclusive: string[];
    };
  }[];
}

const EmailFlowAndInvestigativeSteps: React.FC<
  EmailFlowAndInvestigativeStepsProps
> = ({
  emailImageUrl,
  caseDetail,
  analyses = [], // Default to an empty array if analyses is undefined
}) => {
  const [showEmailImage, setShowEmailImage] = useState(true); // Track toggle state
  const theme = useTheme();

  const handleToggleView = () => {
    setShowEmailImage((prev) => !prev); // Toggle the view
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(72vh - 64px)",
        gap: "1rem",
        alignItems: "flex-start",
        flexGrow: 1,
      }}
    >
      {/* Toggle button with icon */}
      <Button
        onClick={handleToggleView}
        variant="outlined"
        startIcon={showEmailImage ? <AccountTreeIcon /> : <ImageIcon />}
        sx={{
          color: theme.palette.common.black, // Text color
          borderColor: theme.palette.common.black, // Border color for outlined variant
          "&:hover": {
            borderColor: theme.palette.grey[400], // Darker border on hover
            backgroundColor: theme.palette.grey[400], // Light background on hover (optional)
            color: theme.palette.common.white,
          },
        }}
      >
        {showEmailImage ? "View Event Graph" : "View Email Image"}
      </Button>

      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            flexBasis: "70%", // Increase the width to make it wider
            flexGrow: 1,
            maxWidth: "70%",
            height: "100%",
          }}
        >
          {showEmailImage ? (
            <EmailImageSection emailImageUrl={emailImageUrl} />
          ) : (
            <ReactFlowSection
              key="react-flow-section"
              data={caseDetail.graph}
            />
          )}
        </Box>

        <Box
          sx={{
            flexBasis: "30%",
            flexGrow: 1,
            maxWidth: "30%",
            height: "100%",
          }}
        >
          <InvestigativeStepsTabs analyses={analyses} />
        </Box>
      </Box>
    </Box>
  );
};

export default EmailFlowAndInvestigativeSteps;

import { ui_shapes, ui_global_settings } from "./ui_settings";

// These are constants that avoid recalculation for variables
const FULL_CIRCLE_ANG = 2*Math.PI;
const HALF_CIRCLE_ANG = Math.PI;

// Since the canvas renders from top to bottom with y-values increasing
// the arc function (used to draw circles) starts drawing a  circle's at it's rightmost point, clockwise.
// So the top and bottom of a circle is at 3pi/2 and pi/2 respectively
const TOP_CIRCLE_ANG = 3*Math.PI/2; 
const BOTTOM_CIRCLE_ANG = Math.PI/2;

// These are utility functions
// Gets the x-coord along the circumference of a circle at a specified angle
function get_circle_x(center_x, angle, radius) {
    return center_x + Math.cos(angle) * radius;
}
// Gets the y-coord
function get_circle_y(center_y, angle, radius) {
    return center_y + Math.sin(angle) * radius;
}
// Returns the x and y coord as a tuple
function get_circle_coords(center_x, center_y, angle, radius) {
    return [get_circle_x(center_x, angle, radius), get_circle_y(center_y, angle, radius)];
}

// Converts degrees to radians and constrains that value to be between 0 and 2pi
function to_radians(degrees) {
    return (degrees * Math.PI/180) % (FULL_CIRCLE_ANG);
}

// Container class
class Point {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
    draw(ctx, size, color) {
        ctx.beginPath();
        ctx.fillStyle=color;
        ctx.arc(this.x, this.y, size, 0, 2*Math.PI);
        ctx.fill();
        ctx.closePath();
    }
    dist(p) {
        const x_diff = this.x - p.x;
        const y_diff = this.y - p.y;
        return Math.sqrt(x_diff*x_diff + y_diff*y_diff);
    }
}

// Class used to draw the circular graphs seen in the dashboard
class SmoothGraph {
    #points; // x-ascending points array
    #max; // a point object that stores the [max x, max y] (used for normalizing)
    #min; // a point object that stores the [min x, min y] (used for normalizing)
    #f; // interpolation function based on inputted points array
    resolution; // determines how smooth the graph is [0->jagged lines, 1->smoothest curves]
    line_width;
    line_color;
    fill_color;
    point_color;
    point_size;

    constructor(points, resolution, line_width, line_color, fill_color, point_size, point_color) {
        this.update_points(points);
        this.update_properties(resolution, line_width, line_color, fill_color, point_size, point_color);
    }

    update_properties(resolution, line_width, line_color, fill_color, point_size, point_color) {
        this.resolution = Math.min(Math.max(0, resolution), 1); // needs to be between 0 and 1
        this.line_color = line_color;
        this.line_width = line_width;
        this.fill_color = fill_color;
        this.point_color = point_color;
        this.point_size = point_size;
    }

    update_points(points) {
        this.#points = points;
        this.#points.sort((a, b) => a.x - b.x);
        this.#find_bounds();
        this.#f = this.#createHermiteSpline(points);
    }
    
    // This function creates the spline between inputted points.
    // I wouldn't tamper with it, because even though the variable names
    // are intuitive, their initialization is not
    #createHermiteSpline(p) {
        // Deal with edge cases
        if (p.length === 0) { return function(x) { return 0; }; }
        if (p.length === 1) { return function(x) { return p[0].y; }; }

        var i = 0; // index used for loops

        // Get consecutive differences and slopes
        var dys = [], dxs = [], ms = [];
        for (i = 0; i < p.length - 1; i++) {
            const dx = p[i+1].x - p[i].x;
            const dy = p[i+1].y - p[i].y;
            dxs.push(dx); dys.push(dy); ms.push(dy/dx);
        }

        // Get degree-1 coefficients
        var c1s = [ms[0]];
        for (i = 0; i < dxs.length - 1; i++) {
            if (ms[i]*ms[i+1] <= 0) {
                c1s.push(0);
            } else {
                var sum = dxs[i] + dxs[i+1];
                c1s.push(3*sum/((sum + dxs[i+1])/(ms[i]) + (sum + dxs[i])/(ms[i+1])));
            }
        }
        c1s.push(ms[ms.length - 1]);
                
        // Get degree-2 and degree-3 coefficients
        var c2s = [], c3s = [];
        for (i = 0; i < c1s.length - 1; i++) {
            const inv_dx = 1/dxs[i];
            const shared = c1s[i] + c1s[i+1] - 2 * ms[i];
            c2s.push((ms[i] - c1s[i] - shared) * inv_dx);
            c3s.push(shared * inv_dx * inv_dx);
        }

        // Return interpolant function
        // i - index of line segment being calculated (0-based)
        // x - exact x to be computed along line i
        return function(i, x) {
            var diff = x - p[i].x;
            return p[i].y + diff * (c1s[i] + diff *  (c2s[i] + diff * c3s[i]));
        };
    };

    // calculates the #max and #min based on point data
    #find_bounds() {
        if(this.#points.length==0) { // edge case
            this.#max = 1;
            this.#min = 0;
            return;
        }

        var min_y = this.#points[0].y;
        var max_y = this.#points[0].y;

        for(var i = 1; i < this.#points.length; i++) {
            min_y = Math.min(this.#points[i].y, min_y);
            max_y = Math.max(this.#points[i].y, max_y);
        }

        // another edge case, so bounds of graph aren't zero
        if(min_y == max_y) { 
            if(min_y <= 0) { max_y += 5; } // draws line at the bottom of the graph
            else { min_y -= 5; } // draws line at the top of the graph
        }

        this.#max = new Point(this.#points[this.#points.length-1].x, max_y);
        this.#min = new Point(this.#points[0].x, min_y);
    }
                
    // transforms a point p to be within the box specified by x, y, width, height
    // points are normalized by the min and max points calculated above
    #linear_transform(p, x, y, width, height) {
        const new_x = (p.x - this.#min.x)/(this.#max.x-this.#min.x) * width + x;
        const new_y = (y+height) - (p.y - this.#min.y)/(this.#max.y-this.#min.y) * height;
        return new Point(new_x, new_y);
    }
                
    // same as the linear transform, this function transforms a point p to be within a radial segment
    // points are normalized in the same fashion
    #radial_transform(p, x, y, inner_rad, outer_rad, a1, a2) {
        const angle = (p.x - this.#min.x)/(this.#max.x-this.#min.x) * (a2-a1) + a1;
        const radius = (p.y - this.#min.y)/(this.#max.y-this.#min.y) * (outer_rad-inner_rad) + inner_rad;
        return new Point(...get_circle_coords(x, y, angle, radius));
        
    }
                
    // used to draw the inputted points as separate dots onto the graph
    #draw_points(ctx, transform) {
        this.#points.forEach(point => transform(point).draw(ctx, this.point_size, this.point_color));
    }
                
    // draws the lines between points in the points array based on the interpolant function (f)
    // The fill and transform functions determine where these calculated points are actually plotted
    // and how they are drawn
    #draw(ctx, transform, fill) {                    
        ctx.beginPath();
        ctx.lineWidth=this.line_width;
        ctx.strokeStyle=this.line_color;
        ctx.fillStyle=this.fill_color;
        
        var last = transform(this.#points[0]);
        var curr = null;
        ctx.moveTo(last.x, last.y);
    
        // draw each line segment of graph based on interpolation function (f)
        for (var i = 1; i < this.#points.length; i++) {
            // use distance between endpoints of the segment and the resolution factor
            // to determine how many points need to be calculated
            curr = transform(this.#points[i]);
            const dist = last.dist(curr);
            const x_diff = this.#points[i].x - this.#points[i-1].x;
            const x_inc = (x_diff) * ((50-49*this.resolution)/dist);                
            var x = this.#points[i-1].x + x_inc; 
            
            while(x < this.#points[i].x) {
                const P = this.#f(i-1, x); // interpolates point
                const P_new = transform(new Point(x, P)); // graphs it in new window
                ctx.lineTo(P_new.x, P_new.y);
                x+=x_inc;
            }
        
            ctx.lineTo(curr.x, curr.y);
            last = curr;
        }
        
        ctx.stroke();
        fill();
        ctx.closePath();
    }
                
    linear_draw(ctx, x, y, width, height, fill, points) {
        if(!this.#points.length) { return; }
        
        // so lines don't go outside of bounding box
        var offset = this.line_width/2; 
        if(points) { offset = Math.max(offset, this.point_size); }
        
        var transform = (p) => {
            return this.#linear_transform(p, x, y+offset, width, height-offset);
        };
        
        var fill_function = () => {
            if(!fill) { return; }
            ctx.lineTo(x+width, y+height);
            ctx.lineTo(x, y+height);
            ctx.lineTo(x, transform(this.#points[0]).y);
            ctx.fill();
        }
        
        this.#draw(ctx, transform, fill_function);
        if(points) { this.#draw_points(ctx, transform); }
    }

    radial_draw(ctx, x, y, inner_rad, outer_rad, a1, a2, fill, points) {
        if(!this.#points.length) { return; }
       
        // so lines don't go outside of bounding box
        var offset = this.line_width/2; 
        if(points) { offset = Math.max(offset, this.point_size); }
       
        var transform = (p) => {
            return this.#radial_transform(p, x, y, inner_rad+offset, outer_rad-offset, a1, a2);
        }
        
        // unique fill function, has to connect back to starting point of graph along an arc
        var fill_function = () => {
            if(!fill) { return; }
            
            ctx.lineTo(...get_circle_coords(x, y, a2, inner_rad));
            ctx.arc(x, y, inner_rad, a2, a1, true);
            ctx.moveTo(...get_circle_coords(x, y, a1, inner_rad));
            const first = transform(this.#points[0]);
            ctx.lineTo(first.x, first.y);
            ctx.fill();
            ctx.closePath();
        }
    
        this.#draw(ctx, transform, fill_function);
        if(points) { this.#draw_points(ctx, transform); }
    }
}

// Creates n-sided polygons for drawing on a Canvas
// All polygon's are circumbscribed in a circle of a specified radius
// All polygon's point toward's the top of the screen
export class SymmetricPolygon {
    #sides; // private
    #ang_per_side;

    constructor(num_sides, line_width, line_color, fill_color, hover_line_color, hover_fill_color) {
        this.update_sides(num_sides);
        this.line_width = line_width;
        this.line_color = line_color;
        this.fill_color = fill_color;
        this.hover_fill_color = hover_fill_color;
        this.hover_line_color = hover_line_color;
    }
            
    // limits to 10-sided polygon
    // internally a circle is stored as 1-sided to avoid / by 0 errors
    update_sides(num_sides) { 
        this.#sides = Math.min((num_sides < 3) ? 1 : num_sides, 10);
        this.#ang_per_side = FULL_CIRCLE_ANG / this.#sides;
    }

    // removes the polygon from the canvas
    clear(ctx, x, y, radius) {
        radius = radius + this.line_width; // ensures polygon outline is gone
    
        ctx.save();
        ctx.globalCompositeOperation = 'destination-out';
        ctx.beginPath();
        ctx.fillStyle="#000000"; 
        this.#plot_polygon_path(ctx, x, y, radius);
        ctx.fill();
        ctx.closePath();
        ctx.globalCompositeOperation = 'source-over';
        ctx.restore();
    }

    // plots the edges of a polygon, assuming all styles were set beforehand
    #plot_polygon_path(ctx, x, y, radius) {
        var angle = TOP_CIRCLE_ANG;
        
        ctx.moveTo(...get_circle_coords(x, y, angle, radius));
    
        while(angle <= TOP_CIRCLE_ANG + FULL_CIRCLE_ANG) {
            ctx.lineTo(...get_circle_coords(x, y, angle, radius));
            angle += this.#ang_per_side;
        }
    }

    // sets the styles based on if the shape is being hovered or not
    // then draws the polygon
    draw(ctx, x, y, radius, hover) {
        radius = radius-this.line_width/2;
        
        ctx.beginPath();
        ctx.fillStyle = (hover) ? this.hover_fill_color : this.fill_color;
        ctx.strokeStyle = (hover) ? this.hover_line_color : this.line_color;
        ctx.lineWidth = this.line_width;
        
        if(this.#sides == 1) { // just draw a circle
            ctx.arc(x, y, radius, 0, FULL_CIRCLE_ANG);
        } else {
            this.#plot_polygon_path(ctx, x, y, radius);
        }
        
        ctx.fill();
        if(this.line_width!=0) { ctx.stroke(); }
        ctx.closePath();
    }

    // check if current mouse x and mouse y are in the polygon
    is_inside(mouse_x, mouse_y, x, y, radius) {
        const dist = new Point(x, y).dist(new Point(mouse_x, mouse_y));
        if(dist > radius) { return false; }
        
        // only need to do a distance calc for a circle click detect
        if(this.#sides==1) { return dist <= radius; }

        // otherwise have to do some calculations
        const angle = (Math.atan2(y-mouse_y, x-mouse_x) + TOP_CIRCLE_ANG)%this.#ang_per_side - this.#ang_per_side/2;
        return dist * Math.cos(angle) <= radius * Math.cos(this.#ang_per_side/2);
    }
}

// Used to draw multiple polygons over each other
export class CompositePolygon {
    constructor(polygons, size_distribution) {
        this.polygons = polygons;
        this.size = size_distribution;  // assumed input is [1, 0<x<1, ....]
                                        // so the largest polygon is drawn first
    }   
    
    draw(ctx, x, y, radius, hover) {
        for(var i = 0; i < this.polygons.length; i++) {
            this.polygons[i].draw(ctx, x, y, radius*this.size[i], hover);
        }
    }

    // Wrapper functions for the symmetric polygon class, calls it on largest (background) polygon
    clear(ctx, x, y, radius) {
        this.polygons[0].clear(ctx, x, y, radius);
    }

    is_inside(mouse_x, mouse_y, x, y, radius) {
        return this.polygons[0].is_inside(mouse_x, mouse_y, x, y, radius*this.size[0]);
    } 
}

// This class stores all the triangles/clickable objects on the inside of the dashboard          
class Hotspot {
    constructor(shape_name, callback_function, params, size) {
        this.shape = ui_shapes[shape_name];
        this.callback_function = callback_function;
        
        // first two params of any callback function must be x and y
        // callback_function(x, y, ...params) 
        // where other params are specified in an array
        this.params = params; // array
        this.size = size; // the label 'large', 'medium', or 'small'
        
        // mimicking polar coordinates relative to dashboard center
        this.angle = 0; // angle of hotspot location
        this.perc_dist = 0; // distance away from center, as fraction of dashboard radius
        this.perc_rad = 0; // the actual size of the hotspot, as a fraction of dashboard radius
    }

    // Wrapper functions for the shape classes
    // center_x, center_y, and radius are all related to the dashboard
    // and are used to calculate the polar position for the hotspot
    is_inside(mouse_x, mouse_y, center_x, center_y, ang_offset, radius) {
        const [x, y] = get_circle_coords(center_x, center_y, this.angle + ang_offset, this.perc_dist*radius);
        return this.shape.is_inside(mouse_x, mouse_y, x, y, this.perc_rad*radius);
    }

    clear(ctx, center_x, center_y, ang_offset, radius) {
        const [x, y] = get_circle_coords(center_x, center_y, this.angle + ang_offset, this.perc_dist*radius);
        this.shape.clear(ctx, x, y, this.perc_rad*radius);
    }

    draw(ctx, center_x, center_y, ang_offset, radius, hover) {
        const [x, y] = get_circle_coords(center_x, center_y, this.angle + ang_offset, this.perc_dist*radius);
        this.shape.draw(ctx, x, y, this.perc_rad*radius, hover);
    }
                
    // bound x and bound y are the coordinates of where the canvas is on the page
    // useful for offsetting the return point if the hotspot is clicked
    click(click_x, click_y, bound_x, bound_y, center_x, center_y, ang_offset, radius) {
        const [x, y] = get_circle_coords(center_x, center_y, this.angle + ang_offset, this.perc_dist*radius);
        if(this.shape.is_inside(click_x, click_y, x, y, this.perc_rad*radius)) {
            try {
                window[this.callback_function](Math.floor(bound_x+x), Math.floor(bound_y+y), ...this.params);
            } catch {
                // could specify an error message for this type of problem
                // either function name wasn't recognized or params was malformed
            }
            return true;
        }
        return false;
    }
}

// Another container class to store the label and shapes drawn for radio buttons
// in their selected or unselected states
class Button {
    constructor(label, selected_name, unselected_name) {
        this.label = label;
        this.selected = ui_shapes[selected_name];
        this.unselected = ui_shapes[unselected_name]
    }
}

class RadioButton {
    #last_hovered_id;
    // contains default values if none are inputted
    constructor(title="", buttons=[], selected_id=0, ca=0, ang_length=0, ang_margin=0, callback_fnc="", selected_label_color="#000000", 
                unselected_label_color="#000000", label_size=0.6, label_offset=0, label_y_offset=0) {
        this.title = title;
        this.buttons = buttons;
        this.selected_id = selected_id;
        this.center_ang = ca;
        this.ang_length = ang_length;
        this.ang_margin = ang_margin;
        this.callback_fnc = callback_fnc;
        this.selected_label_color = selected_label_color;
        this.unselected_label_color = unselected_label_color;
        this.label_size = label_size;
        this.label_offset = label_offset;
        this.label_y_offset = label_y_offset;
        this.#last_hovered_id = -1;
    }

    // properly draws the label next to each button
    #draw_text(ctx, index, button_x, button_y, button_rad, font_name, font_weight, selected) {
        ctx.fillStyle = (selected) ? this.selected_label_color : this.unselected_label_color;        
        ctx.font = '' + font_weight + ' ' + Math.floor(button_rad*this.label_size) + 'px ' + font_name; 
        
        const txt = ctx.measureText(this.buttons[index].label);
        const half_height = (txt.actualBoundingBoxAscent + txt.actualBoundingBoxDescent)/2;
        const y_offset = this.label_y_offset*button_rad;
        var label_x = button_x + button_rad + this.label_offset*button_rad;
        
        // if button is on the left of the dashboard, draw the label to the left of the button (otherwise do the opposite)
        if(this.center_ang > BOTTOM_CIRCLE_ANG && this.center_ang < TOP_CIRCLE_ANG) {
            label_x = button_x - button_rad - this.label_offset*button_rad - txt.width;
        }
    
        ctx.clearRect(label_x, button_y-half_height+y_offset, txt.width, 3*half_height); 
        ctx.fillText(this.buttons[index].label, label_x, button_y+half_height+y_offset);
    }

    // checks if any functions can be preformed
    #valid() {
        return this.buttons.length !== 0 && this.ang_length !== 0; 
    }

    #get_button_angs_and_rad(radius) {
        // assumed input was validated
        const ang_per_button = (this.ang_length-(this.buttons.length-1)*this.ang_margin)/(this.buttons.length);
        const button_rad = radius * ang_per_button/2;
        const start_angle = this.center_ang - this.ang_length/2 + ang_per_button/2;
        return [ang_per_button, button_rad, start_angle]
    }

    // draws all radio buttons in a group
    draw(ctx, center_x, center_y, radius, font_name, font_weight) {
        if(!this.#valid()) { return; }

        const [ang_per_button, button_rad, start_angle] = this.#get_button_angs_and_rad(radius);
        var angle = start_angle;

        for(var i = 0; i < this.buttons.length; i++) {
            const [button_x, button_y] = get_circle_coords(center_x, center_y, angle, radius);
            const shape = (i === this.selected_id) ? this.buttons[i].selected : this.buttons[i].unselected;

            shape.draw(ctx, button_x, button_y, button_rad, false);
            this.#draw_text(ctx, i, button_x, button_y, button_rad, font_name, font_weight, (i===this.selected_id));
            angle += ang_per_button+this.ang_margin;
        }
    }

    // runs the callback function on the clicked buttons x and y
    // returns whether a click was successfully executed or not
    click(click_x, click_y, ctx, center_x, center_y, radius, font_name, font_weight) {
        if(!this.#valid()) { return false; }

        const [ang_per_button, button_rad, start_angle] = this.#get_button_angs_and_rad(radius);
        var angle = start_angle;

        for(var i = 0; i < this.buttons.length; i++) {
            const [button_x, button_y] = get_circle_coords(center_x, center_y, angle, radius);
            angle += ang_per_button+this.ang_margin;

            if(i===this.selected_id) { continue; } // already at that state

            if(this.buttons[i].unselected.is_inside(click_x, click_y, button_x, button_y, button_rad)) {
                // unselect the selected button
                const selected_angle = start_angle + (ang_per_button+this.ang_margin)*this.selected_id;
                const [selected_x, selected_y] = get_circle_coords(center_x, center_y, selected_angle, radius);

                this.buttons[this.selected_id].selected.clear(ctx, selected_x, selected_y, button_rad);
                this.buttons[this.selected_id].unselected.draw(ctx, selected_x, selected_y, button_rad, false);
                this.#draw_text(ctx, this.selected_id, selected_x, selected_y, button_rad, font_name, font_weight, false);

                // redraw newly selected button
                this.selected_id = i;
                this.buttons[i].unselected.clear(ctx, button_x, button_y, button_rad);
                this.buttons[i].selected.draw(ctx, button_x, button_y, button_rad, false);
                this.#draw_text(ctx, i, button_x, button_y, button_rad, font_name, font_weight, true);

                this.#last_hovered_id = -1; // not hovering any button after click

                // run the function after drawing the new button
                setTimeout(() => {
                    try {
                        window[this.callback_fnc](this.buttons[i].label);
                    } catch {
                        // could add an error message heere if things go poorly
                    }
                }, 40);
                return true;
            }
        }
        return false;
    }

    // internal function that will redraw the last hovered button to its original state
    #clear_last_hovered(ctx, center_x, center_y, radius, ang_per_button, button_rad, start_angle) {
        if(this.#last_hovered_id === -1) { return; } // don't need to do anything

        const hovered_angle = start_angle + (ang_per_button+this.ang_margin)*this.#last_hovered_id;
        const [hovered_x, hovered_y] = get_circle_coords(center_x, center_y, hovered_angle, radius);

        this.buttons[this.#last_hovered_id].unselected.clear(ctx, hovered_x, hovered_y, button_rad);
        this.buttons[this.#last_hovered_id].unselected.draw(ctx, hovered_x, hovered_y, button_rad, false);

        this.#last_hovered_id = -1;
    }

    // will draw hover colors when mouse x and mouse y are over an unselected button
    // returns whether the hover operation was executed
    hover(mouse_x, mouse_y, ctx, center_x, center_y, radius) {
        const [ang_per_button, button_rad, start_angle] = this.#get_button_angs_and_rad(radius);
        var angle = start_angle;
        
        for(var i = 0; i < this.buttons.length; i++) {
            const [button_x, button_y] = get_circle_coords(center_x, center_y, angle, radius);
            angle += ang_per_button+this.ang_margin;

            if(i===this.selected_id) { continue; }
            if(this.buttons[i].unselected.is_inside(mouse_x, mouse_y, button_x, button_y, button_rad)) {
                if(this.#last_hovered_id===i) { return true; } // already being hovered
                
                this.#clear_last_hovered(ctx, center_x, center_y, radius, ang_per_button, button_rad, start_angle);
                this.buttons[i].unselected.clear(ctx, button_x, button_y, button_rad);
                this.buttons[i].unselected.draw(ctx, button_x, button_y, button_rad, true);
                this.#last_hovered_id = i;
                
                return true;
            }
        }

        this.#clear_last_hovered(ctx, center_x, center_y, radius, ang_per_button, button_rad, start_angle);
        return false;
    }
}

// This stores the title, hotspots, and graph associate with each segment of the dashboard
class Segment {
    constructor(title, graph) {
        this.title = title;
        this.graph = graph;
        this.hotspots = [];
    }

    draw_graph(ctx, x, y, inner_rad, outer_rad, a1, a2, fill, points) {
        this.graph.radial_draw(ctx, x, y, inner_rad, outer_rad, a1, a2, fill, points);
    }

    // Draws the title of the segment in the outter ring of the dashboard
    // I know this seems like a lot of parameters, but x...a2 just define where the title segment is
    // x,y -> center of dashboard, r1,r2 are the inner and outtter radius of the title ring
    // a1,a2 -> start and end angle of the segment (which are already offset by angle margins)
    // the rest define what font is being used
    draw_title(ctx, x, y, r1, r2, a1, a2, font_name, font_rel_size, font_color, font_weight) {
        const scale_down = 0.9; // rate at which font scales down to adjust for new size
        const radius = (r1+r2)/2;
        const max_width = radius * Math.abs(a2-a1);
        var title_width = max_width+1;
        var title_height = 0;
        var font_size = (r2-r1)*font_rel_size*(1/scale_down);

        // if width is greater than arc, decrease font size, remeasure
        while(title_width > max_width) {
            font_size = Math.floor(font_size * scale_down);
            ctx.font = '' + font_weight + ' ' + font_size + 'px ' + font_name; 
            const txt = ctx.measureText(this.title);
            title_width = txt.width;
            title_height = txt.actualBoundingBoxAscent + txt.actualBoundingBoxDescent;
        }
                    
        ctx.fillStyle = font_color;
        var angle = (a1+a2)/2-title_width/(2*radius);

        // draw each letter and rotate each to be tangent to the dashboard
        for(var i=0; i < this.title.length; i++) {
            const letter_width = ctx.measureText(this.title[i]).width;
            angle += letter_width/(2*radius);

            const [text_center_x, text_center_y] = get_circle_coords(x, y, angle, radius-title_height);

            ctx.save();
            ctx.translate(text_center_x, text_center_y);
            ctx.rotate(angle-3*Math.PI/2);
            ctx.translate(-text_center_x, -text_center_y);
            ctx.fillText(this.title[i], text_center_x-letter_width/2,  text_center_y-title_height/2);
            ctx.restore();

            angle += letter_width/(2*radius);
        }
    }
}

// This is the large object that runs everything
export class Dashboard {
    #globals;
    #center_num_1 = "0";
    #center_num_2 = "0";

    #width = -1; // width of the dashboard (-1 so canvas will resize initially)
    #height;
    #center_x;
    #center_y;
    #radius;
    #segments = [];
    #radio_buttons = [];
    #num_segments = 0;
    #ang_per_segment = 0;
    #dashboard_base_img = null;
    #last_angle = 0;
    #is_animating = false;
    
    #seed = 34241; // seed for internal random function
    #last_hotspot = -1; // used for storing the last hovered hotspot
    
    #canvas; // the element
    #display_ctx;
    #offscreen_canvas; // only for getting smaller images of the dashboard for rotation
    #ctx;

    constructor(canvas_element, initial_width) {
        initial_width = initial_width || canvas_element.width;

        // initialize globals
        this.#globals = Object.assign({}, ui_global_settings); 
        
        // initialize canvases
        this.#canvas = canvas_element;
        this.#display_ctx = this.#canvas.getContext('2d');
        this.#offscreen_canvas = document.createElement('canvas');
        this.#ctx = this.#offscreen_canvas.getContext('2d');
        
        // set up dimensions of dashboard
        this.resize(initial_width);

        // set up listeners for mouse interactions
        this.#canvas.addEventListener('click', (e) => { this.#click(e); } );
        this.#canvas.addEventListener('mousemove', (e) => {this.#hover(e); })
    }

    resize(width) {
        width = Math.max(width, this.#globals.min_canvas_width);
        
        this.#width = width;
        this.#height = Math.floor(this.#width/this.#globals.aspect_ratio);
        this.#canvas.width = this.#width;
        this.#canvas.height =  this.#height;
        this.#center_x = Math.floor(this.#width/2);
        this.#center_y = Math.floor(this.#height/2);

        // tries to avoid ring with radio buttons exceeding the bounds of the canvas
        this.#radius = Math.floor(Math.min(this.#center_y, this.#globals.screen_fraction_used*this.#center_x/this.#globals.ring_distribution[3]) * this.#globals.screen_fraction_used);

        this.#offscreen_canvas.height = this.#radius*2; // offscreen canvas is just the size of dashboard
        this.#offscreen_canvas.width = this.#radius*2;
        
        // only redraw if data is present
        if(this.#num_segments > 0) { this.#draw(); }
        else { this.#draw_display(); }
    }

    #in_dashboard(x, y) {
        const dist = new Point(this.#center_x, this.#center_y).dist(new Point(x, y));
        // distance is within the dashboard but outside of the center ring
        return dist <= this.#radius && dist > this.#radius * this.#globals.ring_distribution[0];
    }

    // goes through every hotspot object and runs the callback function if clicked
    #check_hotspot_click(click_x, click_y, bound_x, bound_y) {
        for(const segment of this.#segments) {
            for(const hotspot of segment.hotspots) {
                if(hotspot.click(click_x, click_y, bound_x, bound_y, this.#center_x, this.#center_y, this.#last_angle, this.#radius)) {
                    return true; // early return if hotspot was clicked
                }
            }
        }
        return false; // no hotspot was clicked
    }

    // goes through every radio button and selects a new button if clicked (returning its label to the callback function)
    #check_radio_click(click_x, click_y) {
        const radio_button_ring_radius = this.#radius*this.#globals.ring_distribution[3];
        for(const radio_button of this.#radio_buttons) {
            if(radio_button.click(  click_x, click_y, this.#display_ctx, this.#center_x, this.#center_y, 
                                    radio_button_ring_radius, this.#globals.font_name, this.#globals.label_font_weight)) {
                return true; // early return
            }
        }
        return false; // no unselected radio button was clicked
    }

    #stop_hovering_last_hotspot(reset) {
        if(this.#last_hotspot === -1) { return; } // don't need to do anything
        this.#last_hotspot.clear(this.#display_ctx, this.#center_x, this.#center_y, this.#last_angle, this.#radius);
        this.#last_hotspot.draw(this.#display_ctx, this.#center_x, this.#center_y, this.#last_angle, this.#radius, false);
        if(reset) { this.#last_hotspot = -1; }
    }

    // returns whether any hotspot object was hovered
    #check_hotspot_hover(mouse_x, mouse_y) {
        for(const segment of this.#segments) {
            for(const hotspot of segment.hotspots) {
                if(hotspot.is_inside(mouse_x, mouse_y, this.#center_x, this.#center_y, this.#last_angle, this.#radius)) {
                    if(this.#last_hotspot === hotspot) { return true; }
                    this.#stop_hovering_last_hotspot(true);
                    // start hovering new hotspot
                    hotspot.clear(this.#display_ctx, this.#center_x, this.#center_y, this.#last_angle, this.#radius);
                    hotspot.draw(this.#display_ctx, this.#center_x, this.#center_y, this.#last_angle, this.#radius, true);
                    this.#last_hotspot = hotspot;
                    return true;
                }
            }
        }
        this.#stop_hovering_last_hotspot(true);
        return false;
    }

    // returns whether a radio button was hovered over, and changes colors of the button to be it's hovered colors
    #check_radio_button_hover(mouse_x, mouse_y) {
        const radio_button_ring_radius = this.#radius*this.#globals.ring_distribution[3];
        for(const radio_button of this.#radio_buttons) {
            if(radio_button.hover(mouse_x, mouse_y, this.#display_ctx, this.#center_x, this.#center_y, radio_button_ring_radius)) {
                return true;
            }
        }
        return false;
    }

    // hovers over objects in the canvas
    #hover(e) {
        const mouse_x = e.clientX-this.#canvas.getBoundingClientRect().x;
        const mouse_y = e.clientY-this.#canvas.getBoundingClientRect().y;

        // no hotspot will be hovered during rotation
        if(!this.#is_animating && this.#in_dashboard(mouse_x, mouse_y) && this.#check_hotspot_hover(mouse_x, mouse_y)) {
            return; // one of the hotspots was hovered
        }

        this.#check_radio_button_hover(mouse_x, mouse_y);           
    }

    // Run a click on the canvas
    #click(e) {
        const bound_x = this.#canvas.getBoundingClientRect().x;
        const bound_y = this.#canvas.getBoundingClientRect().y;
        const click_x = e.clientX-bound_x;
        const click_y = e.clientY-bound_y;

       
        if(!this.#in_dashboard(click_x, click_y)) { 
            this.#check_radio_click(click_x, click_y); 
            return;
        }
        if(this.#is_animating) { return; } // prohibits all clicks on dashboard while animating
        if(this.#check_hotspot_click(click_x, click_y, bound_x, bound_y)) {
            this.#stop_hovering_last_hotspot(false);
            return; 
        }

        // calculate angle to rotate UI just a normal click in dashboard not on hotspot
        // this angle brings the selected segment to the top of the dashboard
        var angle = (Math.atan2(this.#center_y-click_y, this.#center_x-click_x) + TOP_CIRCLE_ANG) % FULL_CIRCLE_ANG;
        const direction = (2 * (angle >= HALF_CIRCLE_ANG) - 1); // -1 or 1
        angle = (Math.round(angle/this.#ang_per_segment)%(this.#num_segments))*this.#ang_per_segment;
        
        if(angle > HALF_CIRCLE_ANG) { angle = FULL_CIRCLE_ANG-angle; }
        
        this.#animate_rotation(direction * angle);
    }

    // updates the graph based on recently inputted global variables
    // if a segment has a property that differs from a global it wont be updated
    // unless the override flag was set
    #update_graph(copy, override) {
        if(override) {
            this.#segments.forEach(segment => 
            segment.graph.update_properties(
                copy.graph_resolution, 
                copy.graph_line_width, 
                copy.graph_line_color, 
                copy.graph_fill_color, 
                copy.point_size, 
                copy.point_color));
            return;
        }
                    
        // if any of the graph settings weren't initially set, alter it globally
        this.#segments.forEach(segment => {
            if(segment.graph.resolution == this.#globals.graph_resolution) {
                segment.graph.resolution = copy.graph_resolution;
            }
            if(segment.graph.line_width == this.#globals.graph_line_width) {
                segment.graph.line_width = copy.graph_line_width;
            }
            if(segment.graph.line_color == this.#globals.graph_line_color) {
                segment.graph.line_color = copy.graph_line_color;
            }
            if(segment.graph.fill_color == this.#globals.graph_fill_color) {
                segment.graph.fill_color = copy.graph_fill_color;
            }
            if(segment.graph.point_size == this.#globals.point_size) {
                segment.graph.point_size = copy.point_size;
            }
            if(segment.graph.point_color == this.#globals.point_color) {
                segment.graph.point_color = copy.point_color;
            }
        });
    }

    // this also corresponds to global updates with the override flag
    // radio buttons have 2 attached global properties
    #update_radio(global_copy, override) {
        this.#radio_buttons.forEach(rb => {
            if(override) {
                rb.selected_label_color = global_copy.selected_label_color;
                rb.unselected_label_color = global_copy.general_label_color;
                return;
            }
            if(rb.selected_label_color == this.#globals.selected_label_color) {
                rb.selected_label_color = global_copy.selected_label_color;
            } 
            if(rb.unselected_label_color == this.#globals.general_label_color) {
                rb.unselected_label_color = global_copy.general_label_color;
            }
        });
    }
                
    update_globals(json_string, override) {
        const obj = JSON.parse(json_string);
        var global_copy = Object.assign({}, this.#globals);
        var update_hotspots = false;
        var update_radio = false;
        var resize = false;
        for(const property in obj) {
            if(global_copy[property] !== undefined) {
                global_copy[property] = obj[property];
                if (property==="num_per_band" || property==="band_distribution" ||
                    property==="band_margin" || property==="radius_margin") {
                    update_hotspots = true;
                }
                if (property==="selected_label_color" || property==="general_label_color") {
                    update_radio = true;
                }
                if (property==="aspect_ratio") { resize = true; }
            }
        }
        // the following function calls will propogate changes to assigned objects
        this.#update_graph(global_copy, override);
        if(update_radio) { this.#update_radio(global_copy, override); }
        // reassign global_copy to #globals
        for(const property in global_copy) { 
            this.#globals[property] = global_copy[property];
        }
        if(update_hotspots) { this.#place_hotspots(); }
        
        // recalculates radius if the ring distribution or screen fraction used changed
        this.#radius = Math.floor(Math.min(this.#center_y, this.#globals.screen_fraction_used*this.#center_x/this.#globals.ring_distribution[3]) * this.#globals.screen_fraction_used);
        
        if(resize) { this.resize(this.#canvas.width); return; }
        if(this.#globals.min_canvas_width > this.#width) { 
            this.resize(this.#globals.min_canvas_width); 
            return; 
        }
        // recalculate graphic
        this.#draw();
    }

    reset_globals() {
        this.#globals = null;
        this.#globals = Object.assign({}, ui_global_settings);
        this.#update_graph(this.#globals, true);
        this.#update_radio(this.#globals, true);
        this.#draw();
    }

    // function that takes json input and updates existing segments keyed by their title
    // if the reset flag is set all segment data is cleared and repopulated
    // returns titles of segments that are clipped
    update_segments(json_string, reset) {
        const obj = JSON.parse(json_string)["Segments"];
        if(reset) { 
            this.#segments = null; // to prompt garbage collection
            this.#segments = [];
        }
        if(obj===undefined) { return; }
        for(const property in obj) {
            var segment; // set up segment to be pushed or replaced
            if(!reset) { 
                var ind = this.#segments.findIndex(s => s.title === property);
                if(ind==-1) { continue; }
                segment = this.#segments[ind];
            } else {
                segment = new Segment(property, null);
            }
            
            var points = [];
            // default properties
            const graph_properties = [
                this.#globals.graph_resolution, 
                this.#globals.graph_line_width, 
                this.#globals.graph_line_color, 
                this.#globals.graph_fill_color, 
                this.#globals.point_size, 
                this.#globals.point_color
            ];
            
            // convert static array into point objects
            if(obj[property]["points"]!==undefined) {
                obj[property]["points"].forEach(point => points.push(new Point(+point[0], +point[1])));
                if(!reset) { segment.graph.update_points(points); }
            }
            if(obj[property]["hotspots"]!==undefined) {
                segment.hotspots = [];
                obj[property]["hotspots"].forEach(a => segment.hotspots.push(
                    new Hotspot(a["shape_name"], a["callback_fnc"], a["params"], a["size"])
                ));
            }
            // check for overriden globals
            if(obj[property]["graph_resolution"]!==undefined) {
                graph_properties[0] = obj[property]["graph_resolution"];
                if(!reset) { segment.graph.resolution = obj[property]["graph_resolution"]; }
            }
            if(obj[property]["graph_line_width"]!==undefined) {
                graph_properties[1] = obj[property]["graph_line_width"];
                if(!reset) { segment.graph.line_width = obj[property]["graph_line_width"]; }
            }
            if(obj[property]["graph_line_color"]!==undefined) {
                graph_properties[2] = obj[property]["graph_line_color"];
                if(!reset) { segment.graph.line_color = obj[property]["graph_line_color"]; }
            }
            if(obj[property]["graph_fill_color"]!==undefined) {
                graph_properties[3] = obj[property]["graph_fill_color"];
                if(!reset) { segment.graph.fill_color = obj[property]["graph_fill_color"]; }
            }
            if(obj[property]["point_size"]!==undefined) {
                graph_properties[4] = obj[property]["point_size"];
                if(!reset) { segment.graph.point_size = obj[property]["point_size"]; }
            }
            if(obj[property]["point_color"]!==undefined) {
                graph_properties[5] = obj[property]["point_color"];
                if(!reset) { segment.graph.point_color = obj[property]["point_color"]; }
            }
            if(reset) {
                segment.graph = new SmoothGraph(points, ...graph_properties);
                this.#segments.push(segment);
            }
        }

        if(reset) { this.#is_animating = false; this.#last_angle = 0; }
        
        this.#num_segments = this.#segments.length;
        this.#ang_per_segment = FULL_CIRCLE_ANG/this.#num_segments;
        var clipped = this.#place_hotspots();
        this.#draw();
        return clipped;
    }

    // same concept as the segment update but with radio buttons
    update_radio_buttons(json_string, reset) {
        const obj = JSON.parse(json_string)["Radio_Buttons"];
        if(reset) { 
            this.#radio_buttons = null; // to prompt garbage collection
            this.#radio_buttons = [];
        }
        if(obj===undefined) { return; }
        for(const property in obj) {
            var buttons = [];
            var new_radio;
            if(!reset) {  // determine what object is begin manipulated
                var ind = this.#radio_buttons.findIndex(rb => rb.title === property);
                if(ind==-1) { continue; }
                new_radio = this.#radio_buttons[ind];
            } else {
                new_radio = new RadioButton(property);
            }
            // only two radio properties with global defaults
            var selected_label_color = this.#globals.selected_label_color;
            var general_label_color = this.#globals.general_label_color;
            
            if(obj[property]["selected_label_color"]!==undefined) {
                selected_label_color = obj[property]["selected_label_color"];
                new_radio.selected_label_color = obj[property]["selected_label_color"];
            }
            if(obj[property]["unselected_label_color"]!==undefined) {
                general_label_color = obj[property]["unselected_label_color"];
                new_radio.unselected_label_color = obj[property]["unselected_label_color"];
            }
            if(obj[property]["buttons"]!==undefined) {
                obj[property]["buttons"].forEach(b => buttons.push(
                    new Button(b["label"], b["selected_shape_name"], b["unselected_shape_name"])
                ));
                new_radio.buttons = buttons;
            }
            if(obj[property]["center_angle"]!==undefined) {
                new_radio.center_ang = to_radians(obj[property]["center_angle"]);
            }
            if(obj[property]["angle_length"]!==undefined) {
                new_radio.ang_length = to_radians(obj[property]["angle_length"]);
            }
            if(obj[property]["angle_margin"]!==undefined) {
                new_radio.ang_margin = to_radians(obj[property]["angle_margin"]);
            }
            if(obj[property]["callback_fnc"]!==undefined) {
                new_radio.callback_fnc = obj[property]["callback_fnc"];
            }
            if(obj[property]["label_size"]!==undefined) {
                new_radio.label_size = obj[property]["label_size"];
            }
            if(obj[property]["selected_id"]!==undefined) {
                new_radio.selected_id = obj[property]["selected_id"];
            }
            if(obj[property]["label_offset"]!==undefined) {
                new_radio.label_offset = obj[property]["label_offset"];
            }
            if(obj[property]["label_y_offset"]!==undefined) {
                new_radio.label_y_offset = obj[property]["label_y_offset"];
            }
            if(reset) {
                new_radio.selected_label_color = selected_label_color;
                new_radio.unselected_label_color = general_label_color;
                this.#radio_buttons.push(new_radio);
            }
        }
        this.#draw_display();
    }

    // general update function that will run updates on radio buttons and segments
    // if reset flag is set all objects will be overwritten
    update(json_string, reset) {
        const update_obj = JSON.parse(json_string);
        if(update_obj["CN_1"]!==undefined) {
            this.#center_num_1 = update_obj["CN_1"];
        }
        if(update_obj["CN_2"]!==undefined) {
            this.#center_num_2 = update_obj["CN_2"];
        }
        
        if(reset) { this.#last_hotspot = -1; }

        this.update_radio_buttons(json_string, reset);
        // returns all clipped segments
        return this.update_segments(json_string, reset);
    }

    // Generates a random number between 0 and 1
    #rand_function() {
        const m = 0x80000000; // 2^31
        const a = 1103515247;
        const c = 12343;

        // Ensure the seed is a non-negative integer
        this.#seed = this.#seed >>> 0;
        this.#seed = (a * this.#seed + c) % m;
        return this.#seed / m;
    }

    // inclusive
    #get_rand(low, high) {
        return low + Math.floor(this.#rand_function()*(high-low+1));
    }
 
    // places all hotspots within the designated bands based on size signifier
    // placement is done by input order, so excess is disregarded
    // 3 bands SMALL MEDIUM LARGE can place this.#globals.num_per_band in them
    // returns whether the hotspots were clipped or not
    #place_hotspot(segment, hotspots, a) {
        const num_hotspots = hotspots.length;
        if(!num_hotspots) { return false; }

        var added = []; // used to lop off excess input
        const center_ring = this.#globals.ring_distribution[0]*this.#radius;
        const graph_ring = this.#globals.ring_distribution[1]*this.#radius;
        const rad_diff = graph_ring - center_ring;
        const sizes = [center_ring, rad_diff*this.#globals.band_distribution[0]+center_ring, rad_diff*this.#globals.band_distribution[1]+center_ring, graph_ring]
        const margin = center_ring * this.#globals.band_margin;
        
        var band_arr = [];
        for(var i = 0; i < this.#globals.num_per_band; i++) {
            band_arr.push(a+(i+1)*this.#ang_per_segment/(this.#globals.num_per_band+1));
        }
        
        // keeps track of which rays (at certain angles) have been used in individual bands
        var used = [[...band_arr], [...band_arr], [...band_arr]]; // assumes 3 bands
        
        hotspots.forEach(h => {
            var ind = -1;
            if(h.size==="small") { ind = 0; }
            if(h.size==="medium") { ind = 1; }
            if(h.size==="large") { ind = 2; }
            if(ind==-1) { return; } // not valid input
            if(!used[ind].length) { return; } // placed all that I could in that band
            // randomize the distance with a band
            var rad = (1-this.#globals.radius_margin)*(this.#ang_per_segment*sizes[ind])/(2*this.#globals.num_per_band);
            // don't want to intersect bounds of band or other polygons on different rays
            rad = Math.min(rad, (1-this.#globals.radius_margin)*(sizes[ind+1]-sizes[ind]-2*margin)/2);
            const dist = this.#get_rand(Math.ceil(sizes[ind]+rad+margin), Math.floor(sizes[ind+1]-rad-margin));
            // randomize what ray is used
            const b_id = this.#get_rand(0, used[ind].length-1);
            
            h.angle = used[ind][b_id];
            h.perc_dist = dist / this.#radius;
            h.perc_rad = rad / this.#radius;
            added.push(h);
            used[ind].splice(b_id, 1);
        });

        segment.hotspots = [];
        segment.hotspots = added;
        return num_hotspots!=added.length;
    }

    // places all hotspots within the designated bands based on size signifier
    // returns an array of all segment titles that had hotspots clipped
    #place_hotspots() {
        this.#seed = 34241; // resets seed so output is deterministic
        var angle = TOP_CIRCLE_ANG - this.#ang_per_segment/2;

        var clipped = [];

        for(var i = 0; i < this.#num_segments; i++) {
            if(this.#place_hotspot(this.#segments[i], this.#segments[i].hotspots, angle)) {
                clipped.push(this.#segments[i].title);
            }
            angle += this.#ang_per_segment;
        }
        return clipped;
    }

    // draws a circle with an outline (and optional dashes)
    #stroke_circ(ctx, x, y, radius, stroke_color, line_width, dashes) {
        ctx.beginPath();
        ctx.lineWidth = line_width;
        ctx.strokeStyle = stroke_color;
        ctx.setLineDash(dashes);
        ctx.arc(x, y, radius, 0, 2*Math.PI);
        ctx.stroke();
        ctx.setLineDash([]);
        ctx.closePath();
    }

    // draws a circle with a fill color
    #fill_circ(ctx, x, y, radius, fill_color) {
        ctx.beginPath();
        ctx.fillStyle = fill_color;
        ctx.arc(x, y, radius, 0, 2*Math.PI);
        ctx.fill();
        ctx.closePath();
    }

    // draws the title ring on the background/offscreen canvas
    #draw_title_ring() {
        const inner_rad = this.#radius * this.#globals.ring_distribution[2];
        const title_ang = this.#ang_per_segment - 2 * to_radians(this.#globals.title_ring_side_margin);
        var angle = TOP_CIRCLE_ANG - title_ang/2;

        // draws the background ring
        this.#ctx.beginPath();
        this.#ctx.lineWidth = this.#globals.title_ring_line_width;
        this.#ctx.strokeStyle=this.#globals.title_ring_line_color;
        this.#ctx.fillStyle=this.#globals.title_ring_color;
        this.#ctx.arc(this.#radius, this.#radius, inner_rad, 0, 2*Math.PI, true);
        this.#ctx.moveTo(2*this.#radius-this.#ctx.lineWidth/2, this.#radius);
        this.#ctx.arc(this.#radius, this.#radius, this.#radius-this.#ctx.lineWidth/2, 0, 2*Math.PI);
        this.#ctx.fill();
        this.#ctx.stroke();
        this.#ctx.closePath();

        // draws the curved titles per segment
        for(var i = 0; i < this.#num_segments; i++) {
            this.#segments[i].draw_title(
                this.#ctx,
                this.#radius, this.#radius,
                inner_rad, this.#radius, 
                angle, angle+title_ang,
                this.#globals.font_name, this.#globals.title_ring_font_size, 
                this.#globals.title_ring_font_color, this.#globals.title_ring_font_weight
            );

            angle += this.#ang_per_segment;
        }
    }

    // drawing the inner rings on the background canvas
    #draw_inner_rings() {
        this.#fill_circ(this.#ctx, this.#radius, this.#radius, this.#radius, this.#globals.dashboard_background_color);
        
        const inner_graph_rad = this.#globals.ring_distribution[1]*this.#radius;
        const outter_graph_rad = (this.#globals.ring_distribution[2]-this.#globals.title_ring_margin)*this.#radius;
        const line_inc = (outter_graph_rad-inner_graph_rad)/(this.#globals.number_of_dashed_lines);
        
        // calculate dashes to draw for background graph based on dash density
        const dashes = this.#globals.dash_size;
        const whole_dash_size = FULL_CIRCLE_ANG*inner_graph_rad/this.#globals.dash_density;
        const sum = dashes[0] + dashes[1];
        dashes[0] = dashes[0]/sum * whole_dash_size;
        dashes[1] = dashes[1]/sum * whole_dash_size;

        // draws braground dash lines
        this.#stroke_circ(this.#ctx, this.#radius, this.#radius, outter_graph_rad, this.#globals.line_segmentation_color, this.#globals.line_segmentation_width, []);
        for(var r = inner_graph_rad; r < outter_graph_rad; r += line_inc) {
            this.#stroke_circ(this.#ctx, this.#radius, this.#radius, r, this.#globals.line_segmentation_color, this.#globals.line_segmentation_width, dashes);
        }

        // draw the divinding lines between segments
        var angle = TOP_CIRCLE_ANG - this.#ang_per_segment/2;
        while(angle < TOP_CIRCLE_ANG + FULL_CIRCLE_ANG - this.#ang_per_segment/2) {
            this.#ctx.beginPath();
            this.#ctx.moveTo(this.#radius, this.#radius);
            this.#ctx.lineTo(...get_circle_coords(this.#radius, this.#radius, angle, outter_graph_rad));
            this.#ctx.stroke();
            this.#ctx.closePath();
            angle += this.#ang_per_segment;
        }

        // draws center
        const center_rad = this.#globals.ring_distribution[0] * this.#radius;
        this.#fill_circ(this.#ctx, this.#radius, this.#radius, center_rad, this.#globals.dashboard_background_color);
        this.#stroke_circ(this.#ctx, this.#radius, this.#radius, center_rad, this.#globals.line_segmentation_color, this.#globals.line_segmentation_width, []);
    }
                
    // draws the graph on the background canvas
    #draw_graphs() {
        var angle = TOP_CIRCLE_ANG - this.#ang_per_segment/2;
        for(var i = 0 ; i < this.#num_segments; i++) {
            this.#segments[i].draw_graph(
                this.#ctx, this.#radius, this.#radius, 
                this.#radius * this.#globals.ring_distribution[1],
                this.#radius * (this.#globals.ring_distribution[2] - this.#globals.title_ring_margin - this.#globals.graph_top_margin),
                angle + to_radians(this.#globals.margin_between_graphs),
                angle + this.#ang_per_segment - to_radians(this.#globals.margin_between_graphs),
                this.#globals.graph_fill,
                this.#globals.graph_points); 
            angle += this.#ang_per_segment;
        }
    }

    // draws loaded data onto a offscreen/background canvas and saves this as an image
    // it then redraws this image to the display canvas once it has fully loaded
    // this allows for rotation to be optimized (I don't have to recalculate all graphs and text
    // every time I want to rotate, I simply rotate the stored image)
    #draw() {   
        // draw new dashboard
        this.#ctx.clearRect(0, 0, this.#offscreen_canvas.width, this.#offscreen_canvas.height);
        this.#draw_inner_rings();
        this.#draw_title_ring();
        this.#draw_graphs();
        const dataURL = this.#offscreen_canvas.toDataURL();
        this.#dashboard_base_img = new Image();
        this.#dashboard_base_img.src = dataURL;
        this.#dashboard_base_img.addEventListener('load', () => { this.#draw_display(); });
    }

    // Clear everything outside the dashboard
    #clear_around_dashboard() {
        this.#display_ctx.globalCompositeOperation = 'destination-out';
        this.#display_ctx.beginPath();
        this.#display_ctx.fillStyle="#000000";
        this.#display_ctx.moveTo(0,0);
        this.#display_ctx.lineTo(this.#canvas.width, 0);
        this.#display_ctx.lineTo(this.#canvas.width, this.#canvas.height);
        this.#display_ctx.lineTo(0, this.#canvas.height);
        this.#display_ctx.lineTo(0,0);
        this.#display_ctx.moveTo(this.#center_x + this.#radius, this.#center_y);
        this.#display_ctx.arc(this.#center_x, this.#center_y, this.#radius, 0, FULL_CIRCLE_ANG, true);
        this.#display_ctx.fill();
        this.#display_ctx.closePath();
        this.#display_ctx.globalCompositeOperation = 'source-over';
    }

    // draws onto the display canvas
    #draw_display() {
        // if is_animating I don't want to interrupt a rotation with unnecessary frames
        if(this.#is_animating) { this.#clear_around_dashboard(); }
        else { this.#display_ctx.clearRect(0, 0, this.#width, this.#height); }
        
        // can only redraw dashboard when not animating
        if(!this.#is_animating && this.#dashboard_base_img) { this.#rotate(this.#last_angle); } 
        this.#draw_radio_arc(); // draw outter buttons and lines
        this.#draw_buttons();

        if(this.#globals.draw_canvas_outline) {
            this.#display_ctx.strokeStyle = this.#globals.canvas_outline_color;
            this.#display_ctx.strokeRect(0, 0, this.#width, this.#height);
        }
    }
    
    // animate a rotation of the dashboard by an angle
    #animate_rotation(angle) {
        if(angle === 0) { return; } // not rotating the dashboard, no reason to animate
        
        this.#is_animating = true;
        const start = new Date().getTime();
        const total_frames = this.#globals.animation_fps * this.#globals.animation_duration;
        let last_frame = 0;
        var multiplier = 0;
        
        const animate = () => {
            // interrupted by loading of new data, reset last angle
            if(!this.#is_animating) { this.#last_angle = 0; return; }

            const curr = new Date().getTime();
            const elapsed = (curr - start) / 1000;
            
            // draws frames based on time elapsed
            // if frames take two long to draw they will be skipped
            // this is optimized for performance
            if (elapsed < this.#globals.animation_duration) {
                const frame = Math.floor(total_frames * elapsed / this.#globals.animation_duration);
                // avoid redrawing frame
                if (frame !== last_frame) {
                    // animation easing
                    const a = Math.pow(frame/total_frames, this.#globals.animation_ease_exponent);
                    multiplier =  a / (a + Math.pow((1-frame/total_frames), this.#globals.animation_ease_exponent));
                    
                    const rad = this.#last_angle + (angle * multiplier);
                    this.#rotate(rad);
                    last_frame = frame;
                }
                requestAnimationFrame(animate);
        
            } else {
                this.#rotate(this.#last_angle + angle); // final frame
                this.#last_angle = (angle + this.#last_angle)%(FULL_CIRCLE_ANG);
                this.#is_animating = false;
            }
        };
        // start the animation loop
        requestAnimationFrame(animate);
    }

    // rotates the stored image of the dashboard and redraws the center circle and hotspots over it
    #rotate(ang) {
        // clears context of just the dashboard
        this.#display_ctx.save();
        this.#display_ctx.globalCompositeOperation = 'destination-out';
        this.#display_ctx.beginPath();
        this.#display_ctx.arc(this.#center_x, this.#center_y, this.#radius+this.#globals.line_segmentation_width, 0, FULL_CIRCLE_ANG);
        this.#display_ctx.fill();
        this.#display_ctx.closePath();
        this.#display_ctx.globalCompositeOperation = 'source-over';
        
        // redraws dashboard
        this.#display_ctx.translate(this.#center_x, this.#center_y); 
        this.#display_ctx.rotate(ang);
        this.#display_ctx.translate(-this.#center_x, -this.#center_y);
        this.#display_ctx.drawImage(this.#dashboard_base_img, this.#center_x-this.#radius, this.#center_y-this.#radius);
        this.#display_ctx.restore();
        
        this.#draw_center();
        this.#draw_hotspots(ang);
        
        // draws bounding box on canvas
        if(this.#globals.draw_canvas_outline) {
            this.#display_ctx.strokeStyle= this.#globals.canvas_outline_color;
            this.#display_ctx.strokeRect(0, 0, this.#width, this.#height);
        }
    }

    /* ALL FUNCTIONS BELOW ARE DRAWING TO THE DISPLAY CANVAS */
    #draw_radio_arc() {
        if(!this.#globals.draw_radio_arc) { return; }

        const start_ang = to_radians(this.#globals.radio_arc_start_ang);
        const end_ang = to_radians(this.#globals.radio_arc_end_ang);

        this.#display_ctx.beginPath();
        this.#display_ctx.strokeStyle=this.#globals.radio_arc_color;
        this.#display_ctx.lineWidth=this.#globals.radio_arc_width;
        this.#display_ctx.arc(this.#center_x, this.#center_y, this.#radius*this.#globals.ring_distribution[3], start_ang, end_ang);
        this.#display_ctx.stroke();
        this.#display_ctx.closePath();
    }
                
    #draw_hotspots(ang) {
        this.#segments.forEach(segment => segment.hotspots.forEach(hotspot => 
            hotspot.draw(this.#display_ctx, this.#center_x, this.#center_y, ang, this.#radius, false)
        ));
    }

    #draw_buttons() {
        this.#radio_buttons.forEach(rb => rb.draw(
            this.#display_ctx, this.#center_x, this.#center_y, this.#radius*this.#globals.ring_distribution[3],
            this.#globals.font_name, this.#globals.label_font_weight));
    }

    #draw_center() {
        // font-size is stored in the globals, and doesn't readjust if it is bigger than the initial inner circle radius
        // but everything scales properly, so if the input is good, this function will work as intended
        const radius = this.#radius*this.#globals.ring_distribution[0];
        const font_size = this.#globals.center_label_font_size*radius;
        this.#display_ctx.fillStyle = this.#globals.center_label_font_color;
        this.#display_ctx.font = '' + this.#globals.center_label_font_weight + ' ' + font_size + 'px ' + this.#globals.font_name;
        
        var txt = this.#display_ctx.measureText(this.#globals.center_label_1_txt);
        var max_height = txt.actualBoundingBoxAscent + txt.actualBoundingBoxDescent;
        txt = this.#display_ctx.measureText(this.#globals.center_label_2_txt);
        max_height = Math.max(max_height, txt.actualBoundingBoxAscent + txt.actualBoundingBoxDescent);
        
        const number_margin = this.#globals.center_label_number_margin*radius;
        const between_margin = this.#globals.center_label_margin*radius
        const box_height = (1+this.#globals.number_to_label_ratio)*max_height + number_margin;
        const top_margin = radius-box_height - between_margin;
        
        // draw labels
        txt = this.#display_ctx.measureText(this.#globals.center_label_1_txt);
        this.#display_ctx.fillText(this.#globals.center_label_1_txt, this.#center_x-txt.width/2, this.#center_y-radius+top_margin+max_height);
        txt = this.#display_ctx.measureText(this.#globals.center_label_2_txt);
        this.#display_ctx.fillText(this.#globals.center_label_2_txt, this.#center_x-txt.width/2, this.#center_y+between_margin+max_height);
        
        // draw numbers
        this.#display_ctx.fillStyle = this.#globals.center_number_font_color;
        this.#display_ctx.font = '' + this.#globals.center_number_font_weight + ' ' + this.#globals.number_to_label_ratio * max_height + 'px ' + this.#globals.font_name;
        txt = this.#display_ctx.measureText(this.#center_num_1);
        this.#display_ctx.fillText(this.#center_num_1, this.#center_x-txt.width/2, this.#center_y-between_margin);
        txt = this.#display_ctx.measureText(this.#center_num_2);
        this.#display_ctx.fillText(this.#center_num_2, this.#center_x-txt.width/2, this.#center_y+radius-top_margin);
    }
}
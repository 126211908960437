import React from "react";
import { Box, Typography } from "@mui/material";

interface ErrorDisplayProps {
  error: string;
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ error }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h6">Error fetching cases: {error}</Typography>
    </Box>
  );
};

export default ErrorDisplay;

// index.tsx
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { Auth0Provider } from "@auth0/auth0-react";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={
        process.env.REACT_APP_AUTH0_DOMAIN ||
        "dev-s4e2eie1uptlq3qg.us.auth0.com"
      }
      clientId={
        process.env.REACT_APP_AUTH0_CLIENT_ID ||
        "VbxkRAdQiS3qwbeiGJn9ES3GLIHrL60L"
      }
      authorizationParams={{
        audience:
          process.env.REACT_APP_AUTH0_AUDIENCE ||
          "https://app.embedsecurity.com/emaildemo/",
        redirect_uri: window.location.origin,
      }}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();

import React, { useEffect, useMemo } from "react";
import {
  ReactFlow,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  Node,
  Edge,
  Controls,
  Background,
  useReactFlow,
} from "@xyflow/react";
import dagre from "@dagrejs/dagre";
import "@xyflow/react/dist/style.css";
import CustomNode from "./CustomNode"; // A custom node component
import CustomEdge from "./CustomEdge"; // A custom edge component
import "../styles/ReactFlowSection.css";
import { useTheme } from "@mui/system";

// Initialize a Dagre graph
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

// Node and edge sizes for the layout
const nodeWidth = 150;
const nodeHeight = 150;

// The layout direction is horizontal from left to right
const layoutDirection = "LR"; // "LR" for left-to-right, "TB" for top-to-bottom

// Helper function for domain name extraction
const extractDomain = (url: string): string => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname.replace(/^www\./, "");
  } catch (e) {
    // Fallback for invalid URLs
    const matches = url.match(/^https?:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
    let domain = matches && matches[1] ? matches[1] : url;
    return domain.replace(/^www\./, "");
  }
};

// Node transformation function
const transformNodes = (nodesData: any[]): Node[] => {
  return nodesData.map((nodeData) => {
    let label = nodeData.displayName || nodeData.id;
    // For nodes of kind "URL", extract domain for the label
    if (nodeData.kind === "URL") {
      label = extractDomain(label);
    }
    return {
      id: nodeData.id,
      position: { x: 0, y: 0 },
      data: {
        label,
        type: nodeData.kind,
        timestamp: nodeData.timestamp || "",
        isTrigger: nodeData.isTrigger || false,
      },
      type: "custom",
    };
  });
};

// Edge transformation function
const transformEdges = (edgesData: any[]): Edge[] => {
  return edgesData.map((edgeData) => {
    const sourceId = edgeData.u;
    const targetId = edgeData.v;
    return {
      id: `${sourceId}-${targetId}`,
      source: sourceId,
      target: targetId,
      type: "custom",
      // Not animated by default
      data: {
        sourceIsTrigger: edgeData.sourceIsTrigger,
        targetIsTrigger: edgeData.targetIsTrigger,
      },
    };
  });
};

// Layout function using Dagre
const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
  dagreGraph.setGraph({
    rankdir: layoutDirection,
    align: "UL",
    nodesep: 80,
    ranksep: 100,
  });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    // Each edge is set from source to target
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  return nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };
    return node;
  });
};

// The main ReactFlowSection component
const ReactFlowSection = ({
  data,
}: {
  data: { nodes: any[]; edges: any[] };
}) => {
  const theme = useTheme();
  const initialNodes = useMemo(() => transformNodes(data.nodes), [data.nodes]);
  const initialEdges = useMemo(() => transformEdges(data.edges), [data.edges]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.grey[200],
      }}
    >
      <ReactFlowProvider>
        <Flow initialNodes={initialNodes} initialEdges={initialEdges} />
      </ReactFlowProvider>
    </div>
  );
};

const Flow = ({
  initialNodes,
  initialEdges,
}: {
  initialNodes: Node[];
  initialEdges: Edge[];
}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const nodeTypes = { custom: CustomNode };
  const edgeTypes = { custom: CustomEdge };
  const proOptions = { hideAttribution: true };
  const { fitView } = useReactFlow();

  // Apply layout only on initial mount
  useEffect(() => {
    const layoutedNodes = getLayoutedElements([...nodes], [...edges]);
    setNodes(layoutedNodes);
    fitView({ padding: 0.2, duration: 500 });
    // We do not re-layout on every node/edge change
    // because this effect runs only on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      fitView
      nodesDraggable
      proOptions={proOptions}
      style={{ width: "100%", height: "100%" }}
    >
      <Background /> {/* Using default background style */}
      <Controls position="top-left" />
    </ReactFlow>
  );
};

export default ReactFlowSection;

import { SymmetricPolygon, CompositePolygon } from "./ui_classes";

// Any color below can take on rgba or hex values

// Each symmetric polygon is defined by (#num of sides, line_width, line_color, fill_color, hover_line_color, hover_fill_color)
// So, switching to a different style set for hovering over a polygon is done automatically
// Composite polygons just draw symmetric polygons over each other at the sizes specified in the following array

// Wouldn't recommend setting the line_width to zero, even if you don't want an outline
// Instead change the line_color to be the same as the fill color
export const ui_shapes = {
    "To Me": new SymmetricPolygon(3, 2, "#77C0F4", "rgba(119, 192, 244, 0.3)", "#77C0F4", "rgba(119, 192, 244, 0.5)"),
    "To Others": new SymmetricPolygon(3, 2, "#A196F7", "rgba(161, 150, 247, 0.3)", "#A196F7", "rgba(161, 150, 247, 0.5)"),
    "Unassigned": new SymmetricPolygon(3, 2, "#989AA0", "rgba(152, 154, 160, 0.3)", "#989AA0", "rgba(152, 154, 160, 0.5)"),
    "Show All": new SymmetricPolygon(3, 2, "rgba(230, 121, 213, 1)", "rgba(230, 121, 213, 0.3)", "rgba(230, 121, 213, 1)", "rgba(230, 121, 213, 0.5)"),
    "Unselected Radio": new SymmetricPolygon(0, 1, "rgba(223, 244, 251, 1)", "rgba(223, 244, 251, 1)", "rgba(223, 244, 251, 1)", "rgba(192, 234, 248, 1)"),
    
    "Selected Radio": new CompositePolygon(     [   new SymmetricPolygon(0, 2, "rgba(224, 225, 232, 1)", "rgba(254, 253, 255, 1)", "rgba(224, 225, 232, 1)", "rgba(254, 253, 255, 1)"), 
                                                    new SymmetricPolygon(0, 0, "rgba(94, 64, 246, 1.0)", "rgba(94, 64, 246, 1.0)", "rgba(94, 64, 246, 1.0)", "rgba(94, 64, 246, 1.0)"),
                                                    new SymmetricPolygon(0, 0, "rgba(254, 253, 255, 1)", "rgba(254, 253, 255, 1)", "rgba(254, 253, 255, 1)", "rgba(254, 253, 255, 1)")],
                                                [ 1, 0.6, 0.16]),
    
    "To Me Selected": new CompositePolygon(     [   new SymmetricPolygon(3, 2, "#77C0F4", "rgba(119, 192, 244, 0.0)", "#77C0F4", "rgba(119, 192, 244, 0.0)"),
                                                    new SymmetricPolygon(3, 2, "#77C0F4", "rgba(119, 192, 244, 0.3)", "#77C0F4", "rgba(119, 192, 244, 0.3)")],
                                                [1, 0.6]),

    "To Others Selected": new CompositePolygon( [   new SymmetricPolygon(3, 2, "#A196F7", "rgba(161, 150, 247, 0)", "#A196F7", "rgba(161, 150, 247, 0)"),
                                                    new SymmetricPolygon(3, 2, "#A196F7", "rgba(161, 150, 247, 0.3)", "#A196F7", "rgba(161, 150, 247, 0.3)")],
                                                [1, 0.6]),
                                                
    "Unassigned Selected": new CompositePolygon([   new SymmetricPolygon(3, 2, "#989AA0", "rgba(152, 154, 160, 0)", "#989AA0", "rgba(152, 154, 160, 0)"),
                                                    new SymmetricPolygon(3, 2, "#989AA0", "rgba(152, 154, 160, 0.3)", "#989AA0", "rgba(152, 154, 160, 0.3)")],
                                                [1, 0.6]),    
                                                                 
    "Show All Selected": new CompositePolygon(  [   new SymmetricPolygon(3, 2, "rgba(230, 121, 213, 1)", "rgba(230, 121, 213, 0)", "rgba(230, 121, 213, 1)", "rgba(230, 121, 213, 0)"),
                                                    new SymmetricPolygon(3, 2, "rgba(230, 121, 213, 1)", "rgba(230, 121, 213, 0.3)", "rgba(230, 121, 213, 1)", "rgba(230, 121, 213, 0.3)")],
                                                [1, 0.6]),
};

export const ui_global_settings = {
    min_canvas_width: 600, // in pixels
    aspect_ratio: 1.5, // canvas width/height
    screen_fraction_used: 0.9, // percent of canvas_height the dashboard uses
    font_name: 'Arial, Helvetica', // can put the name of a loaded CSS font here
    dashboard_background_color: '#FAFCFE', // background color for the inner parts of the dashboard
    
    // for canvas debugging (turn this off for product release)
    draw_canvas_outline: true, 
    canvas_outline_color: "#0000FF",

    // radio arc (the curved line below the time filter radio buttons)
    draw_radio_arc: true,
    radio_arc_color: 'rgba(228, 242, 254, 1)',
    radio_arc_start_ang: 0, // needs to be intersected first when rotating clockwise
    radio_arc_end_ang: 60, // compared to this angle
    radio_arc_width: 4, // all angles above, including this one are in degrees

    //title-ring properties
    title_ring_font_size: 0.35, // a proportion of the title ring's (outter-inner radius)
    title_ring_side_margin: 2, // in degrees
    title_ring_color: '#EFF1FE',
    title_ring_font_weight: 'bold', // options for font-weights are [normal bold bolder lighter]
    title_ring_font_color: '#4a4b4f',
    title_ring_line_color: '#DDDEFE',
    title_ring_line_width: 2,
    
    // segment graph properties
    graph_line_width: 3, // in pixels
    graph_line_color: '#77C0F4',
    graph_fill_color: 'rgba(119, 192, 244, 0.3)',
    graph_resolution: 1.0, // goes from 0->jagged lines to 1->smooth curves
    graph_points: false,
    graph_fill: true,
    point_size: 4, // in pixels
    point_color: '#77C0F4',

    // graph margins
    margin_between_graphs: 2, // angle in degrees
    title_ring_margin: 0.03, // margin between end of graph ring and start of title ring
    graph_top_margin: 0.02, // margin between end of graph ring and top of graph
    
    // center properties
    center_label_font_color: '#A5A7AB',
    center_label_1_txt: 'Escalated Cases',
    center_label_2_txt: 'Auto Triaged Cases',
    center_label_font_weight: 'bold', // options for font-weights are [normal bold bolder lighter]
    center_number_font_color: '#17181C',
    center_number_font_weight: 'bold', // options for font-weights are [normal bold bolder lighter]
    number_to_label_ratio: 2.5,
    center_label_font_size: 0.15, // proportion of inner ring radius
    center_label_number_margin: 0.05 , // proportion of inner ring radius
    center_label_margin: 0.11, // proporition of inner ring radius

    // labels
    general_label_color: '#999DA0',
    selected_label_color: '#999DA0',
    label_font_weight: 'bold', // options for font-weights are [normal bold bolder lighter]
    
    //lines
    line_segmentation_color: '#E4E5E9',
    line_segmentation_width: 2,
    number_of_dashed_lines: 4,
    dash_density: 200, // dashes per inner_graph_ring 
    dash_size: [1, 1], // numbers only used for relativity
    
    // center/innner ring, hotspot ring, graph ring, [0.9->1] title ring,  radio button ring
    ring_distribution: [0.22, 0.6, 0.9, 1.1],

    // rotation properties
    animation_ease_exponent: 1.7, // 1<=x<=2 ==> 1->linear, 2->very smooth easing
    animation_duration: 1.5, // in seconds
    animation_fps: 120,

    // hotspot dispersal properties
    num_per_band: 3,
    band_distribution: [0.25, 0.6], // proportion of hotspot ring radius that MEDIUM and LARGE hotspots start
    band_margin: 0.05, // to ensure hotspots don't go over band lines
    radius_margin: 0.1 // to ensure hotspots don't intersect
};
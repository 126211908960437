import React from "react";
import { EdgeProps, getBezierPath, Position } from "@xyflow/react";
import "../styles/CustomEdges.css";

interface EdgeData {
  sourceIsTrigger?: boolean;
  targetIsTrigger?: boolean;
}

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  style = {},
  data,
}: EdgeProps) => {
  // Generate the edge path
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition: Position.Right, // Evaluate edge direction
    targetX,
    targetY,
    targetPosition: Position.Left, // Evaluate edge direction
  });

  // Determine if either the source or target node is a trigger
  const isTriggerEdge =
    (data as EdgeData)?.sourceIsTrigger || (data as EdgeData)?.targetIsTrigger;

  // Conditionally set the edge style
  const edgeStyle = {
    ...style,
    stroke: isTriggerEdge ? "red" : "#222",
    strokeWidth: 1,
  };

  // Assign appropriate CSS classes
  const edgeClassName = `react-flow__edge-path custom-edge ${
    isTriggerEdge ? "trigger-edge" : ""
  }`;

  return (
    <>
      <defs>
        <marker
          id={`arrowhead-${id}`}
          markerWidth="10"
          markerHeight="7"
          refX="10"
          refY="3.5"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <polygon
            points="0 0, 10 3.5, 0 7"
            fill={isTriggerEdge ? "red" : "#222"}
          />
        </marker>
      </defs>
      <path
        id={id}
        style={edgeStyle}
        className={edgeClassName} // Ensure this includes 'react-flow__edge-path'
        d={edgePath}
        markerEnd={`url(#arrowhead-${id})`}
      />
    </>
  );
};

export default CustomEdge;

import { createSlice } from "@reduxjs/toolkit";
import { fetchCaseDetail } from "../../api/caseDetailsApi";
import { CaseDetails } from "../../types/caseDetailsTypes";

interface CaseDetailState {
  caseDetail: CaseDetails | null;
  loading: boolean;
  error: string | null;
}

const initialState: CaseDetailState = {
  caseDetail: null,
  loading: false,
  error: null,
};

const caseDetailSlice = createSlice({
  name: "caseDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCaseDetail.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchCaseDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.caseDetail = action.payload;
    });
    builder.addCase(fetchCaseDetail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Failed to fetch case detail";
    });
  },
});

export default caseDetailSlice.reducer;

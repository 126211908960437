import React, { useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Avatar,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Case } from "../../src/types/caseTypes";
import ConclusionIndicator from "../components/ConclusionIndicator";
import ReviewedDisplay from "../components/ReviewedDisplay";
import DeviceChip from "../components/DeviceChip";
import AttackSurfaceDisplay from "../components/AttackSurfaceDisplay";
import IdentityDisplay from "../components/IdentityDisplay";
import AlertTimeDisplay from "../components/AlertTimeDisplay";
import IStepsDisplay from "./IStepsDisplay";
import FeedbackDisplay from "./FeedbackDisplay";
import embedLogo from "../assets/single_embed_logo.svg";
import CaseTitleTooltip from "./CasesTitleTooltip";
import SeverityIndicatorDisplay from "./SeverityIndicatorDisplay";

interface CasesTableProps {
  cases: Case[];
  page: number;
  rowsPerPage: number;
  order: "asc" | "desc" | undefined;
  orderBy: keyof Case | undefined;
  setOrder: React.Dispatch<React.SetStateAction<"asc" | "desc" | undefined>>;
  setOrderBy: React.Dispatch<React.SetStateAction<keyof Case | undefined>>;
}

const CasesTable: React.FC<CasesTableProps> = ({
  cases,
  page,
  rowsPerPage,
  order,
  orderBy,
  setOrder,
  setOrderBy,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const headerCellStyles = {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[500],
    fontWeight: "bold",
    borderBottom: `2px solid ${theme.palette.grey[200]}`,
  };

  const handleCopyId = (id: string) => {
    navigator.clipboard.writeText(id);
    // Removed the alert, replaced it with icon change in the tooltip component
  };

  const handleSort = (property: keyof Case) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isValidConclusion = (
    conclusion: string
  ): conclusion is "Benign" | "Suspicious" | "Malicious" => {
    return ["Benign", "Suspicious", "Malicious"].includes(conclusion);
  };

  const getConclusionOrder = (conclusion: string): number => {
    switch (conclusion) {
      case "Malicious":
        return 3;
      case "Suspicious":
        return 2;
      case "Benign":
        return 1;
      default:
        return 0;
    }
  };

  const getSeverityOrder = (severity: string): number => {
    switch (severity) {
      case "Critical":
        return 4;
      case "High":
        return 3;
      case "Medium":
        return 2;
      case "Low":
        return 1;
      default:
        return 0;
    }
  };

  const sortedCases = [...cases].sort((a, b) => {
    if (orderBy) {
      const aValue = a[orderBy];
      const bValue = b[orderBy];

      if (aValue == null && bValue != null) return order === "asc" ? -1 : 1;
      if (aValue != null && bValue == null) return order === "asc" ? 1 : -1;
      if (aValue == null && bValue == null) return 0;

      if (aValue < bValue) {
        return order === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return order === "asc" ? 1 : -1;
      }
    }

    const conclusionOrderA = getConclusionOrder(a.conclusion);
    const conclusionOrderB = getConclusionOrder(b.conclusion);

    if (conclusionOrderA !== conclusionOrderB) {
      return conclusionOrderB - conclusionOrderA;
    }

    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();

    if (!isNaN(dateA) && !isNaN(dateB) && dateA !== dateB) {
      return dateB - dateA;
    }

    const severityOrderA = getSeverityOrder(a.severity);
    const severityOrderB = getSeverityOrder(b.severity);

    if (severityOrderA !== severityOrderB) {
      return severityOrderB - severityOrderA;
    }

    return 0;
  });

  const paginatedCases = sortedCases.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  return (
    <Box sx={{ maxWidth: "100%", overflowX: "auto" }}>
      <TableContainer component={Paper} sx={{ maxHeight: "100%" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: theme.palette.common.white, // White background
                borderBottom: `2px solid ${theme.palette.grey[300]}`, // Bottom border for header row
              }}
            >
              <TableCell sx={headerCellStyles}>
                <TableSortLabel
                  active={orderBy === "caseId"}
                  direction={orderBy === "caseId" ? order || "asc" : "asc"}
                  onClick={() => handleSort("caseId")}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={headerCellStyles}>
                <TableSortLabel
                  active={orderBy === "conclusion"}
                  direction={orderBy === "conclusion" ? order || "asc" : "asc"}
                  onClick={() => handleSort("conclusion")}
                >
                  Conclusion
                </TableSortLabel>
              </TableCell>
              <TableCell sx={headerCellStyles}>iSteps</TableCell>
              <TableCell sx={headerCellStyles}>
                <TableSortLabel
                  active={orderBy === "feedback"}
                  direction={orderBy === "feedback" ? order || "asc" : "asc"}
                  onClick={() => handleSort("feedback")}
                >
                  Feedback
                </TableSortLabel>
              </TableCell>
              <TableCell sx={headerCellStyles}>
                <TableSortLabel
                  active={orderBy === "severity"}
                  direction={orderBy === "severity" ? order || "asc" : "asc"}
                  onClick={() => handleSort("severity")}
                >
                  Severity
                </TableSortLabel>
              </TableCell>
              <TableCell sx={headerCellStyles}>
                <TableSortLabel
                  active={orderBy === "createdAt"}
                  direction={orderBy === "createdAt" ? order || "asc" : "asc"}
                  onClick={() => handleSort("createdAt")}
                >
                  Alert Rcvd
                </TableSortLabel>
              </TableCell>
              <TableCell sx={headerCellStyles}>
                <TableSortLabel
                  active={orderBy === "alertTimestamp"}
                  direction={
                    orderBy === "alertTimestamp" ? order || "asc" : "asc"
                  }
                  onClick={() => handleSort("alertTimestamp")}
                >
                  Alert Time
                </TableSortLabel>
              </TableCell>
              <TableCell sx={headerCellStyles}>
                <TableSortLabel
                  active={orderBy === "identity"}
                  direction={orderBy === "identity" ? order || "asc" : "asc"}
                  onClick={() => handleSort("identity")}
                >
                  Identity
                </TableSortLabel>
              </TableCell>
              <TableCell sx={headerCellStyles}>
                <TableSortLabel
                  active={orderBy === "device"}
                  direction={orderBy === "device" ? order || "asc" : "asc"}
                  onClick={() => handleSort("device")}
                >
                  Device
                </TableSortLabel>
              </TableCell>
              <TableCell sx={headerCellStyles}>
                <TableSortLabel
                  active={orderBy === "attackSurface"}
                  direction={
                    orderBy === "attackSurface" ? order || "asc" : "asc"
                  }
                  onClick={() => handleSort("attackSurface")}
                >
                  Attack Surface
                </TableSortLabel>
              </TableCell>
              <TableCell sx={headerCellStyles}>
                <TableSortLabel
                  active={orderBy === "mitreStage"}
                  direction={orderBy === "mitreStage" ? order || "asc" : "asc"}
                  onClick={() => handleSort("mitreStage")}
                >
                  MITRE
                </TableSortLabel>
              </TableCell>
              <TableCell sx={headerCellStyles}>
                <TableSortLabel
                  active={orderBy === "analyst"}
                  direction={orderBy === "analyst" ? order || "asc" : "asc"}
                  onClick={() => handleSort("analyst")}
                >
                  Analyst
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedCases.map((caseItem) => (
              <TableRow
                key={caseItem.caseId}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                  "& td": {
                    borderBottom: "none", // Remove borders for all cells
                  },
                }}
              >
                <TableCell
                  sx={{
                    maxWidth: 350,
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                  }}
                >
                  {/* Use the new CaseTitleTooltip component */}
                  <CaseTitleTooltip
                    caseItem={caseItem}
                    handleCopyId={handleCopyId}
                  />
                </TableCell>

                <TableCell>
                  {isValidConclusion(caseItem.conclusion) ? (
                    <ConclusionIndicator conclusion={caseItem.conclusion} />
                  ) : (
                    <ConclusionIndicator conclusion="Benign" />
                  )}
                </TableCell>
                <TableCell>
                  <IStepsDisplay analyses={caseItem.analyses} />
                </TableCell>
                <TableCell>
                  {caseItem.feedback === "agree" ||
                  caseItem.feedback === "disagree" ? (
                    <FeedbackDisplay feedback={caseItem.feedback} />
                  ) : (
                    <Typography variant="body2">{caseItem.feedback}</Typography>
                  )}
                </TableCell>
                <TableCell>
                  <SeverityIndicatorDisplay severity={caseItem.severity} />
                </TableCell>
                <TableCell>{caseItem.createdAt}</TableCell>
                <TableCell>
                  <AlertTimeDisplay timestamp={caseItem.alertTimestamp} />
                </TableCell>
                <TableCell>
                  <IdentityDisplay identity={caseItem.identity} />
                </TableCell>
                <TableCell>
                  <DeviceChip device={caseItem.device} />
                </TableCell>
                <TableCell>
                  <AttackSurfaceDisplay caseType={caseItem.attackSurface} />
                </TableCell>
                <TableCell>{caseItem.mitreStage}</TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      alt={caseItem.analyst}
                      src={
                        caseItem.analyst === "Embed"
                          ? embedLogo
                          : `https://i.pravatar.cc/40?u=${caseItem.analyst}`
                      }
                      sx={{
                        marginRight: theme.spacing(1),
                        height: "1em",
                        width: "1em",
                      }}
                    />
                    <Typography variant="body2">{caseItem.analyst}</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CasesTable;

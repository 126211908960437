import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
    primary: {
      main: '#705FFF',
      light: '#7986cb',
      dark: '#303f9f',
    },
    secondary: {
      main: '#416BFF',
      light: '#FF708B',
      dark: '#f50057',
    },
    error: {
      main: '#DF3232',
      light: '#FFEBEB', // Light red for subtle error backgrounds
    },
    warning: {
      main: '#F6BB22',
    },
    info: {
      main: '#079FFA',
      dark: "#0277BD", // example darker variant
      light: '#E6F4FF', // Light blue for info background or highlights
    },
    success: {
      main: '#09B898',
      light: '#E0F7F4', // Light green for success backgrounds
    },
    grey: {
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: '"Inter", "Montserrat", "Helvetica", "Arial", sans-serif',
    h3: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      fontSize: "2rem",
    },
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        root: {
          "& .MuiBadge-badge:not(.MuiBadge-dot)": {
            backgroundColor: "#E6F4FF",
            color: "#29A5FF",
            fontWeight: "bold",
            padding: "0 4px",
            fontSize: "0.75rem",
            borderRadius: "4px",
            minWidth: "20px",
            top: "-5px",
            right: "-15px",
          },
        },
      },
    },
  },
});

export default theme;

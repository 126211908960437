import React, { useEffect } from "react";
import { Container, Box, Typography, Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import EmbedLogo from "../assets/single_embed_logo.svg";

const LoginPage = () => {
  const { loginWithRedirect, isAuthenticated, isLoading, error } = useAuth0();
  const navigate = useNavigate();

  // Redirect to /cases if user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/cases", { replace: true });
    }
  }, [isAuthenticated, navigate]);

  // Show a loading spinner while Auth0 is checking login status
  if (isLoading) {
    return (
      <Container
        maxWidth="sm"
        style={{ textAlign: "center", marginTop: "100px" }}
      >
        <CircularProgress />
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Checking login status...
        </Typography>
      </Container>
    );
  }

  // If there is an error during login
  if (error) {
    return (
      <Container
        maxWidth="sm"
        style={{ textAlign: "center", marginTop: "100px" }}
      >
        <Typography variant="h6" color="error">
          Error: {error.message}
        </Typography>
      </Container>
    );
  }

  // Main login page
  return (
    <Container maxWidth="sm" style={{ marginTop: "100px" }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        bgcolor="background.paper"
        borderRadius="8px"
        padding="32px"
        boxShadow={3}
      >
        <Avatar sx={{ bgcolor: "primary.main", mb: 2 }}>
          <LockOutlinedIcon />
        </Avatar>
        {/* <Box>
          <img
            src={EmbedLogo}
            alt="Embed Company Logo"
            style={{ width: "40px", marginTop: "1em", marginBottom: "1em" }}
          />
        </Box> */}
        <Typography variant="h4" gutterBottom>
          Welcome Back
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Please log in to access your account
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          style={{ marginTop: "20px" }}
          onClick={() => loginWithRedirect()}
        >
          Log In
        </Button>
      </Box>
    </Container>
  );
};

export default LoginPage;

export const formatDate = (dateString: string): string => {
    return new Date(dateString).toLocaleDateString("en-US");
  };
  

// Utility function to format date to 'YYYY-MM-DD'
export const formatAlertDate = (timestamp: string) => {
  const date = new Date(timestamp);
  if (isNaN(date.getTime())) {
    return timestamp; // Return the original value if it's not a valid date
  }
  return date.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
};
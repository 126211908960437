import React from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import { parseIdentity } from "../utils/parseIdentity";

// Reusable component to show name with email on hover
const IdentityDisplay = ({ identity }: { identity: string }) => {
  const { name, email } = parseIdentity(identity);

  return (
    <Box>
      {email ? (
        <Tooltip title={email} placement="bottom-start">
          <Typography variant="body2" sx={{ cursor: "pointer" }}>
            {name}
          </Typography>
        </Tooltip>
      ) : (
        <Typography variant="body2">{name || "<none>"}</Typography>
      )}
    </Box>
  );
};

export default IdentityDisplay;

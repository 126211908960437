// Utility function to split the identity string into name and email
export const parseIdentity = (identity: string) => {
    if (!identity) {
      return { name: null, email: null };
    }

    const match = identity.match(/(.*?)<(.+?)>/);
    if (match) {
      return { name: match[1].trim(), email: match[2].trim() };
    }
    // If no email is found, return just the name
    return { name: identity, email: null }; 
  };
  
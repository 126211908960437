import React from "react";
import { Box, Typography, Badge } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface TopBarProps {
  totalCases: number;
}

const TopBar: React.FC<TopBarProps> = ({ totalCases }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(2),
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h3" fontWeight="bold">
          Cases
        </Typography>
        <Badge badgeContent={totalCases} color="primary" />
      </Box>
    </Box>
  );
};

export default TopBar;

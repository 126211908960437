import React from "react";
import { Box, Tooltip, useTheme } from "@mui/material";
import { styled, keyframes } from "@mui/system";

interface Analysis {
  name: string;
  conclusion: string | null;
}

interface IStepsDisplayProps {
  analyses: Analysis[];
}

// Define the pulsating animation
const pulsate = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.4);
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

// Create a styled component for the dots - TODO: Probably will refactor everything to this later..just yoloing now
const Dot = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMalicious" && prop !== "color",
})<{ isMalicious: boolean; color: string }>(
  ({ theme, isMalicious, color }) => ({
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: color,
    cursor: "pointer",
    position: "relative",
    ...(isMalicious && {
      animation: `${pulsate} 1.5s infinite`,
      "@media (prefers-reduced-motion: reduce)": {
        animation: "none",
      },
    }),
  })
);

const IStepsDisplay: React.FC<IStepsDisplayProps> = ({ analyses }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(4, auto)",
        gap: theme.spacing(0.5),
        width: "auto",
      }}
    >
      {analyses.map((analysis, index) => {
        let color;
        let isMalicious = false;

        if (analysis.conclusion === "Benign") {
          color = theme.palette.success.main;
        } else if (analysis.conclusion === "Malicious") {
          color = theme.palette.error.main;
          isMalicious = true;
        } else {
          color = theme.palette.grey[500];
        }

        return (
          <Tooltip
            key={index}
            title={`${analysis.name}: ${
              analysis.conclusion || "No Conclusion"
            }`}
            arrow
          >
            <Dot color={color} isMalicious={isMalicious} />
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default IStepsDisplay;

import React, { useEffect } from "react";
import { Box } from "@mui/material";
import CaseSummarySection from "../components/CaseSummarySection";
import EmailFlowAndInvestigativeSteps from "../components/EmailFlowAndInvestigativeSteps";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch } from "../app/hooks";
import { RootState } from "../app/rootReducer";
import { useSelector } from "react-redux";
import { fetchCaseDetail } from "../api/caseDetailsApi";
import CaseDetailsLoadingSkeleton from "../components/CaseDetailsIsLoadingSkeleton"; // Import the skeleton component

const CaseDetailsPage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>(); // Get the id from URL params
  const { getAccessTokenSilently } = useAuth0();

  // Redux store data
  const caseDetail = useSelector(
    (state: RootState) => state.caseDetails.caseDetail
  );
  const loading = useSelector((state: RootState) => state.caseDetails.loading);
  const error = useSelector((state: RootState) => state.caseDetails.error);

  const emailImageUrl = id
    ? `${process.env.REACT_APP_EMAIL_IMAGE_URL}/${id}`
    : "";

  // Fetch case details on mount
  useEffect(() => {
    const fetchData = async () => {
      if (!id) return; // Exit if id is undefined

      try {
        const token = await getAccessTokenSilently();
        dispatch(fetchCaseDetail({ accessToken: token, caseId: id }));
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };
    fetchData();
  }, [dispatch, getAccessTokenSilently, id]);

  if (loading) return <CaseDetailsLoadingSkeleton />; // Use the skeleton component here
  if (error) return <div>Error: {error}</div>;

  const handleAgree = () => {
    console.log("Agree button clicked");
  };

  const handleDisagree = () => {
    console.log("Disagree button clicked");
  };

  return (
    <Box sx={{ padding: "2rem" }}>
      {caseDetail && (
        <>
          <CaseSummarySection
            caseTitle={caseDetail.title}
            caseConclusion={caseDetail.conclusion}
            keyIndicators={caseDetail.keyIndicators}
            caseNumber={caseDetail.id}
            createdOn={caseDetail.createdAt}
            alertTime={
              caseDetail.alerts && caseDetail.alerts.length > 0
                ? caseDetail.alerts[0].timestamp
                : ""
            }
            analyst={caseDetail.analyst || ""} // Use caseDetail analyst if available
            analystAvatar={caseDetail.analystAvatar || ""} // Use caseDetail avatar if available
            status={caseDetail.status || "Unreviewed"} // Use caseDetail status if available
            severity={caseDetail.severity}
            device={caseDetail.attackSurface}
            from={
              caseDetail.alerts &&
              caseDetail.alerts.length > 0 &&
              caseDetail.alerts[0].data
                ? caseDetail.alerts[0].data.from_address
                : ""
            }
            description={caseDetail.summary}
            onAgree={handleAgree}
            onDisagree={handleDisagree}
          />

          <EmailFlowAndInvestigativeSteps
            emailImageUrl={emailImageUrl}
            caseDetail={caseDetail}
            analyses={
              caseDetail.details?.analyses
                ? caseDetail.details.analyses.map((analysis) => ({
                    ...analysis,
                    conclusion: analysis.conclusion ?? "",
                  }))
                : []
            }
          />
        </>
      )}
    </Box>
  );
};

export default CaseDetailsPage;

import React from "react";
import {
  Box,
  Skeleton,
  Avatar,
  Typography,
  Button,
  useTheme,
} from "@mui/material";

const CaseDetailsLoadingSkeleton = () => {
  const theme = useTheme();

  return (
    <Box sx={{ padding: theme.spacing(4) }}>
      {/* Case Summary Section Skeleton */}
      <Box sx={{ marginBottom: theme.spacing(4) }}>
        {/* Top Row: Title and Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: theme.spacing(2),
          }}
        >
          {/* Left Side: Title */}
          <Box sx={{ width: "70%" }}>
            <Skeleton variant="text" width="80%" height={40} />
            <Skeleton variant="text" width="60%" height={30} sx={{ mt: 1 }} />
          </Box>

          {/* Right Side: Buttons */}
          <Box sx={{ display: "flex", gap: theme.spacing(2) }}>
            <Skeleton variant="rectangular" width={100} height={40} />
            <Skeleton variant="rectangular" width={100} height={40} />
          </Box>
        </Box>

        {/* Second Row: Details */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(4),
            marginBottom: theme.spacing(2),
          }}
        >
          {/* Created On */}
          <Skeleton variant="text" width={120} height={20} />

          {/* Last Updated */}
          <Skeleton variant="text" width={120} height={20} />

          {/* Analyst */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="text" width={80} height={20} sx={{ ml: 1 }} />
          </Box>

          {/* Status */}
          <Skeleton variant="text" width={80} height={20} />
        </Box>

        {/* Key Evidence and Description */}
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(2),
            marginTop: theme.spacing(3),
          }}
        >
          {/* Key Evidence */}
          <Box
            sx={{
              flex: 1,
              backgroundColor: theme.palette.grey[50],
              padding: theme.spacing(2),
              borderRadius: "8px",
            }}
          >
            <Skeleton variant="text" width="40%" height={30} sx={{ mb: 2 }} />
            {[...Array(3)].map((_, index) => (
              <Skeleton
                key={index}
                variant="text"
                width="90%"
                height={20}
                sx={{ mb: 1 }}
              />
            ))}
          </Box>

          {/* Description */}
          <Box
            sx={{
              flex: 1,
              backgroundColor: theme.palette.grey[50],
              padding: theme.spacing(2),
              borderRadius: "8px",
            }}
          >
            <Skeleton variant="text" width="40%" height={30} sx={{ mb: 2 }} />
            <Skeleton variant="rectangular" width="100%" height={80} />
          </Box>
        </Box>
      </Box>

      {/* Email Flow and Investigative Steps Skeleton */}
      <Box
        sx={{
          display: "flex",
          gap: theme.spacing(2),
          alignItems: "flex-start",
          height: "calc(72vh - 64px)",
        }}
      >
        {/* Email Image Section Skeleton */}
        <Box
          sx={{
            flexBasis: "50%",
            flexGrow: 1,
            maxWidth: "70%",
            height: "100%",
            backgroundColor: theme.palette.background.paper,
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Skeleton variant="rectangular" width="100%" height="100%" />
        </Box>

        {/* Investigative Steps Tabs Skeleton */}
        <Box
          sx={{
            flexBasis: "30%",
            flexGrow: 1,
            maxWidth: "50%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
            overflowY: "auto",
          }}
        >
          {/* Tabs */}
          <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
            {[...Array(4)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width="80px"
                height="40px"
              />
            ))}
          </Box>

          {/* Content */}
          <Box
            sx={{
              flexGrow: 1,
              backgroundColor: theme.palette.grey[200],
              padding: theme.spacing(2),
              borderRadius: "8px",
            }}
          >
            {[...Array(5)].map((_, index) => (
              <Skeleton
                key={index}
                variant="text"
                width="100%"
                height={20}
                sx={{ mb: 1 }}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CaseDetailsLoadingSkeleton;

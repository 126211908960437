import { createAsyncThunk } from "@reduxjs/toolkit";
import { mockCaseDetailData } from "../fixtures/case_detail_v2";
import { CaseDetails } from "../types/caseDetailsTypes";
import { useAuth0 } from "@auth0/auth0-react";

const fetchCaseDetailMock = () => {
  return new Promise<CaseDetails>((resolve) => {
    setTimeout(() => {
      resolve(mockCaseDetailData);
    }, 2000); // Simulates 2-second network delay
  });
};

export const fetchCaseDetail = createAsyncThunk(
  "caseDetail/fetchCaseDetail",
  async (
    { accessToken, caseId }: { accessToken: string; caseId: string },
    { rejectWithValue }
  ) => {
    if (!accessToken) {
      return rejectWithValue("User is not authenticated");
    }
    const apiUrl = `${process.env.REACT_APP_CASE_DETAILS_URL}/${caseId}`; // Append caseId to URL
    console.log(apiUrl);
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        // Parse error response
        const errorData = await response.json();
        return rejectWithValue(
          errorData.message || "Failed to fetch case details"
        );
      }

      const data = await await response.json();
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to fetch case detail");
    }
  }
);

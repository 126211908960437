import React from "react";
import { Handle, Position } from "@xyflow/react";
import "../styles/CustomNode.css"; // Custom CSS file
import DeviceIcon from "../assets/device_icon.svg";

interface CustomNodeProps {
  data: {
    label: string;
    type: string;
    timestamp: string;
    isTrigger?: boolean;
    icon: string; // Added icon for the node
  };
}

const CustomNode: React.FC<CustomNodeProps> = ({ data }) => {
  const isRootAlert = data.isTrigger;

  return (
    <div className="custom-node-container">
      {/* Display Name on Top of the Node */}
      <div className="custom-node-label-top">
        {isRootAlert ? "Root Alert" : data.label}
      </div>
      {/* Type directly underneath Display Name */}
      <div className="custom-node-type">{data.type}</div>
      {/* Node Circle with Icon in the center */}
      <div className={`custom-node ${isRootAlert ? "trigger-alert" : ""}`}>
        {/* Icon Inside the Node */}
        <img src={DeviceIcon} alt="icon" className="custom-node-icon" />
      </div>

      {/* Timestamp Below the Node */}
      <div className="custom-node-timestamp">{data.timestamp}</div>

      {/* Left and Right Handles for connections */}
      <Handle type="target" position={Position.Left} className="handle-left" />
      <Handle
        type="source"
        position={Position.Right}
        className="handle-right"
      />
    </div>
  );
};

export default CustomNode;

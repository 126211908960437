import React, { useEffect, useRef } from "react";
import { ui_global_settings, ui_shapes } from "../config/ui_settings";
import {
  Dashboard,
  SymmetricPolygon,
  CompositePolygon,
} from "../config/ui_classes";

const EmbedCircleComponent = ({ data }) => {
  const canvasRef = useRef(null);
  const dashboardRef = useRef(null);

  // Initialize dashboard when the component mounts
  useEffect(() => {
    if (canvasRef.current && data) {
      const canvas = canvasRef.current;
      dashboardRef.current = new Dashboard(canvas);

      // Initial dashboard setup and rendering using passed data
      dashboardRef.current.update(JSON.stringify(data), true);

      // Handle window resize events to adjust canvas
      const handleResize = () => {
        const width = Math.floor(window.innerWidth * 0.8); // Adjust width dynamically
        dashboardRef.current.resize(width);
      };

      window.addEventListener("resize", handleResize);

      // Clean up on unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [data]);

  // Render the canvas element
  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
    >
      <canvas
        ref={canvasRef}
        width={1200}
        height={800}
        style={{
          backgroundColor: ui_global_settings.dashboard_background_color,
        }}
      />
    </div>
  );
};

export default EmbedCircleComponent;

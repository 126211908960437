import React from "react";
import "../styles/LoadingAnimation.css";

const LoadingAnimation = () => {
  return (
    <div className="loading-animation">
      <svg
        width="150"
        height="50"
        viewBox="0 0 120 30"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
      >
        {/* First Dot */}
        <circle cx="15" cy="15" r="12" fill="#FFD600">
          <animate
            attributeName="opacity"
            values="0.3;1;0.3"
            dur="1.5s"
            repeatCount="indefinite"
            begin="0s"
          />
        </circle>

        {/* Second Dot */}
        <circle cx="60" cy="15" r="12" fill="#5DBBFF">
          <animate
            attributeName="opacity"
            values="0.3;1;0.3"
            dur="1.5s"
            repeatCount="indefinite"
            begin="0.3s"
          />
        </circle>

        {/* Third Dot */}
        <circle cx="105" cy="15" r="12" fill="#FFD600">
          <animate
            attributeName="opacity"
            values="0.3;1;0.3"
            dur="1.5s"
            repeatCount="indefinite"
            begin="0.6s"
          />
        </circle>
      </svg>
    </div>
  );
};

export default LoadingAnimation;

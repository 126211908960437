import React from "react";
import { Box, Button, Typography, Avatar, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// import EmailIcon from "../assets/case-type/EmailFilled.svg";
import DeviceIcon from "../assets/device_icon.svg";
import IdentityIcon from "../assets/identity_icon.svg";
import SeverityIcon from "../assets/severity_icon.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Icon for Key Evidence bullets
import EmailIcon from "@mui/icons-material/Email";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { Circle, CircleOutlined, CircleTwoTone } from "@mui/icons-material";
interface CaseSummarySectionProps {
  caseNumber: string;
  caseTitle: string;
  caseConclusion: string;
  keyIndicators: string[];
  createdOn: string;
  alertTime: string;
  analyst: string;
  analystAvatar: string;
  status: string;
  severity: string;
  device: string;
  from: string;
  description: string;
  onAgree: () => void;
  onDisagree: () => void;
}

const CaseSummarySection: React.FC<CaseSummarySectionProps> = ({
  caseNumber,
  caseTitle,
  caseConclusion,
  keyIndicators,
  createdOn,
  alertTime,
  analyst,
  analystAvatar,
  status,
  severity,
  device,
  from,
  description,
  onAgree,
  onDisagree,
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ marginBottom: "2rem" }}>
      {/* Top Row: Case Title, Device, Identity, Severity, Agree/Disagree */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: theme.spacing(2),
        }}
      >
        {/* Left side: Case Title, Device, Identity, Severity */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(4),
          }}
        >
          {/* Case Title and Phishing Attack Link */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: theme.spacing(2),
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.info.main,
                marginTop: 0,
                marginBottom: "0.25rem",
              }}
            >
              {/* TODO: Add subject/title */}
              Subject: {caseTitle}
            </Typography>
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                color:
                  caseConclusion === "Benign"
                    ? theme.palette.success.main
                    : theme.palette.error.main,
              }}
            >
              {caseConclusion}
            </Typography>
          </Box>

          {/* Device */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
              marginTop: "0.5rem",
              marginRight: "1.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "40px", // Adjust the size as needed
                height: "40px",
                borderRadius: "50%", // Circle shape
                backgroundColor: "#fff", // White background
              }}
            >
              <EmailIcon
                sx={{ width: 20, height: 20, color: theme.palette.grey[500] }}
              />
            </Box>
            <Box>
              <Typography variant="body2" color={theme.palette.text.secondary}>
                Device
              </Typography>
              <Typography variant="body2">{device}</Typography>
            </Box>
          </Box>

          {/* Identity NOTE: 20241106 - EmailDemo changed this to To*/}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
              marginTop: "0.5rem",
              marginRight: "1.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "40px", // Adjust the size as needed
                height: "40px",
                borderRadius: "50%", // Circle shape
                backgroundColor: "#fff", // White background
              }}
            >
              <PermIdentityIcon
                sx={{ width: 20, height: 20, color: theme.palette.grey[500] }}
              />
            </Box>
            <Box>
              <Typography variant="body2" color={theme.palette.text.secondary}>
                From
              </Typography>
              <Typography variant="body2">{from}</Typography>
            </Box>
          </Box>

          {/* Severity: NOTE: 20241106 - No concept of severity currently in emaildemo */}
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
              marginTop: "0.5rem",
              marginRight: "1.5rem",
            }}
          >
            <img
              src={SeverityIcon}
              alt="Severity Icon"
              style={{ marginRight: "0.5rem" }}
            />
            <Box>
              <Typography variant="body2" color={theme.palette.text.secondary}>
                Severity
              </Typography>
              <Typography variant="body2">{severity}</Typography>
            </Box>
          </Box> */}
        </Box>

        {/* Right side: Agree and Disagree Buttons */}
        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.success.main,
              marginRight: "1rem",
              color: "#fff",
              width: "100px",
              fontFamily: "Montserrat",
            }}
            onClick={onAgree}
          >
            AGREE
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.error.main,
              color: "#fff",
              width: "100px",
              fontFamily: "Montserrat",
            }}
            onClick={onDisagree}
          >
            DISAGREE
          </Button>
        </Box>
      </Box>

      {/* Second Row: Created On, Last Updated, Duration, Analyst, Status */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(4),
          marginBottom: theme.spacing(1),
          fontSize: "0.875rem",
        }}
      >
        <Typography variant="body2" color={theme.palette.text.secondary}>
          Alert Rcvd:{" "}
          <Typography variant="body2" component="span" fontWeight="bold">
            {createdOn}
          </Typography>
        </Typography>

        <Typography variant="body2" color={theme.palette.text.secondary}>
          Alert Time:{" "}
          <Typography variant="body2" component="span" fontWeight="bold">
            {alertTime}
          </Typography>
        </Typography>

        {/* Analyst with Avatar */}
        <Box sx={{ display: "inline-flex", alignItems: "center" }}>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            Analyst:
          </Typography>
          <Avatar
            src={analystAvatar}
            alt={analyst}
            sx={{
              width: "1.5rem",
              height: "1.5rem",
              marginLeft: "0.5rem",
              marginRight: "0.5rem",
            }}
          />
          <Typography variant="body2" component="span" fontWeight="bold">
            {analyst}
          </Typography>
        </Box>

        {/* <Typography variant="body2">
          Status:{" "}
          <Typography variant="body2" component="span" fontWeight="bold">
            {status}
          </Typography>
        </Typography> */}
      </Box>

      {/* Row: Key Evidence and Description Side by Side */}
      <Box
        sx={{
          display: "flex",
          gap: theme.spacing(2),
          marginTop: theme.spacing(3),
        }}
      >
        {/* Key Evidence Section */}
        <Box
          sx={{
            flex: 1,
            backgroundColor: theme.palette.grey[50],
            padding: theme.spacing(2),
            borderRadius: "8px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              marginBottom: theme.spacing(1),
            }}
          >
            Key Evidence
          </Typography>
          <List>
            {keyIndicators.map((indicator, index) => (
              <ListItem
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "flex-start", // Align items at the top
                  paddingLeft: 0,
                  paddingY: "0.25rem",
                }}
              >
                <CircleTwoTone
                  fontSize="small"
                  sx={{
                    color: theme.palette.grey[600],
                    fontSize: "0.8rem", // Adjust the font size as needed
                    marginRight: theme.spacing(1),
                    marginTop: theme.spacing(0.5), // Align bullet with top of text
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.text.primary }}
                >
                  {indicator}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>

        {/* Description Section */}
        <Box
          sx={{
            flex: 1,
            padding: theme.spacing(2),
            backgroundColor: theme.palette.grey[50],
            borderRadius: "8px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", marginBottom: theme.spacing(1) }}
          >
            Description
          </Typography>
          <Typography variant="body2" color={theme.palette.text.primary}>
            {description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CaseSummarySection;

import React from "react";
import EmbedCircleComponent2 from "../components/EmbedCircleComponent2"; // Import the component

const DashboardPage = () => {
  const initialData = {
    CN_1: 12,
    CN_2: 853,
    Segments: {
      "Resource Development": {
        points: [
          [0, 0],
          [10, 40],
          [20, 80],
          [300, 2],
        ],
        hotspots: [
          {
            shape_name: "To Me",
            callback_fnc: "example_function",
            params: [839],
            size: "medium",
          },
          {
            shape_name: "To Others",
            callback_fnc: "example_function",
            params: [940],
            size: "medium",
          },
          {
            shape_name: "Unassigned",
            callback_fnc: "example_function",
            params: [941],
            size: "small",
          },
          {
            shape_name: "To Me",
            callback_fnc: "example_function",
            params: [942],
            size: "large",
          },
        ],
      },
      "Initial Access": {
        points: [
          [0, 0],
          [30, 80],
          [210, 30],
          [500, 90],
        ],
        hotspots: [
          {
            shape_name: "To Me",
            callback_fnc: "example_function",
            params: [10],
            size: "medium",
          },
          {
            shape_name: "To Others",
            callback_fnc: "example_function",
            params: [11],
            size: "medium",
          },
          {
            shape_name: "Unassigned",
            callback_fnc: "example_function",
            params: [12],
            size: "medium",
          },
          {
            shape_name: "Unassigned",
            callback_fnc: "example_function",
            params: [13],
            size: "large",
          },
          {
            shape_name: "To Others",
            callback_fnc: "example_function",
            params: [14],
            size: "small",
          },
          {
            shape_name: "To Me",
            callback_fnc: "example_function",
            params: [15],
            size: "large",
          },
        ],
      },
      Execution: {
        points: [
          [0, 0],
          [20, 80],
          [4, 30],
          [1000, 90],
        ],
        hotspots: [
          {
            shape_name: "To Others",
            callback_fnc: "example_function",
            params: [56],
            size: "medium",
          },
        ],
      },
      Persistence: {
        points: [
          [0, 0],
          [30, 500],
          [210, 200],
          [500, 90],
        ],
      },
      "Privilege Escalation": {
        points: [
          [0, 0],
          [30, 80],
          [210, 800],
          [500, 90],
        ],
        hotspots: [
          {
            shape_name: "To Others",
            callback_fnc: "example_function",
            params: [36],
            size: "large",
          },
          {
            shape_name: "To Others",
            callback_fnc: "example_function",
            params: [37],
            size: "small",
          },
        ],
      },
      "Defense Evasion": {
        points: [
          [0, 0],
          [30, 400],
          [210, 30],
          [500, 900],
        ],
        hotspots: [
          {
            shape_name: "Unassigned",
            callback_fnc: "example_function",
            params: [36],
            size: "medium",
          },
        ],
      },
      "Credential Access": {
        points: [
          [0, 0],
          [30, 80],
          [20, 30],
          [50, 90],
        ],
        hotspots: [
          {
            shape_name: "To Me",
            callback_fnc: "example_function",
            params: [100],
            size: "large",
          },
        ],
      },
      Discovery: {
        points: [
          [0, 0],
          [40, 40],
          [200, 200],
          [4, 90],
        ],
        hotspots: [
          {
            shape_name: "To Me",
            callback_fnc: "example_function",
            params: [18],
            size: "medium",
          },
          {
            shape_name: "To Others",
            callback_fnc: "example_function",
            params: [19],
            size: "medium",
          },
          {
            shape_name: "Unassigned",
            callback_fnc: "example_function",
            params: [20],
            size: "medium",
          },
          {
            shape_name: "Unassigned",
            callback_fnc: "example_function",
            params: [21],
            size: "large",
          },
          {
            shape_name: "To Others",
            callback_fnc: "example_function",
            params: [22],
            size: "small",
          },
          {
            shape_name: "To Me",
            callback_fnc: "example_function",
            params: [23],
            size: "large",
          },
        ],
      },
      "Lateral Movement": {
        points: [
          [0, 0],
          [4, 80],
          [21, 3],
          [10, 9],
        ],
        hotspots: [
          {
            shape_name: "To Me",
            callback_fnc: "example_function",
            params: [89],
            size: "medium",
          },
          {
            shape_name: "Unassigned",
            callback_fnc: "example_function",
            params: [90],
            size: "medium",
          },
          {
            shape_name: "Unassigned",
            callback_fnc: "example_function",
            params: [91],
            size: "small",
          },
          {
            shape_name: "To Me",
            callback_fnc: "example_function",
            params: [92],
            size: "large",
          },
        ],
      },
      Reconnaissance: {
        points: [
          [0, 0],
          [3, 800],
          [210, 300],
          [500, 90],
        ],
        hotspots: [
          {
            shape_name: "To Me",
            callback_fnc: "example_function",
            params: [893],
            size: "medium",
          },
          {
            shape_name: "To Others",
            callback_fnc: "example_function",
            params: [894],
            size: "small",
          },
          {
            shape_name: "Unassigned",
            callback_fnc: "example_function",
            params: [896],
            size: "small",
          },
        ],
        graph_fill_color: "rgba(255,0,0,0.3)",
        graph_line_color: "#FF0000",
      },
    },
    Radio_Buttons: {
      dash_type_filter: {
        center_angle: 180,
        angle_length: 15,
        angle_margin: 2,
        label_offset: 0.35,
        label_size: 0.55,
        callback_fnc: "filter_click",
        selected_id: 1,
        selected_label_color: "#5e40f6",
        buttons: [
          {
            selected_shape_name: "Selected Radio",
            unselected_shape_name: "Unselected Radio",
            label: "Case Type",
          },
          {
            selected_shape_name: "Selected Radio",
            unselected_shape_name: "Unselected Radio",
            label: "Mitre",
          },
        ],
      },
      time_filters: {
        center_angle: 30,
        angle_length: 40,
        angle_margin: 5,
        label_size: 0.55,
        label_offset: 0.35,
        callback_fnc: "filter_click",
        selected_id: 1,
        selected_label_color: "#5e40f6",
        buttons: [
          {
            selected_shape_name: "Selected Radio",
            unselected_shape_name: "Unselected Radio",
            label: "Last 24 Hrs",
          },
          {
            selected_shape_name: "Selected Radio",
            unselected_shape_name: "Unselected Radio",
            label: "Last Week",
          },
          {
            selected_shape_name: "Selected Radio",
            unselected_shape_name: "Unselected Radio",
            label: "Last Month",
          },
          {
            selected_shape_name: "Selected Radio",
            unselected_shape_name: "Unselected Radio",
            label: "Custom",
          },
        ],
      },
      hotspot_filters: {
        center_angle: 330,
        angle_length: 22,
        angle_margin: 2,
        label_size: 0.65,
        label_offset: 0.25,
        label_y_offset: -0.25,
        callback_fnc: "filter_click",
        selected_id: 3,
        buttons: [
          {
            selected_shape_name: "To Me Selected",
            unselected_shape_name: "To Me",
            label: "Assigned to Me",
          },
          {
            selected_shape_name: "To Others Selected",
            unselected_shape_name: "To Others",
            label: "Assigned to Others",
          },
          {
            selected_shape_name: "Unassigned Selected",
            unselected_shape_name: "Unassigned",
            label: "Unassigned",
          },
          {
            selected_shape_name: "Show All Selected",
            unselected_shape_name: "Show All",
            label: "Show All",
          },
        ],
      },
    },
  };

  return <EmbedCircleComponent2 data={initialData} />;
};

export default DashboardPage;

import React, { useRef, useEffect } from "react";
import Lottie from "lottie-react";
import animationData from "../assets/animation_1.json"; // Adjust the path as needed

const MyLottieAnimation = ({ onAnimationComplete, speed  }) => {
  const lottieRef = useRef(null);

  useEffect(() => {
    // Adjust the speed of the animation
    if (lottieRef.current) {
      lottieRef.current.setSpeed(speed);
    }
  }, [speed]);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh", // Full viewport height
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ maxWidth: "733px", width: "100%" }}>
        <Lottie
          lottieRef={lottieRef}
          animationData={animationData}
          loop={false} // Ensure the animation plays only once
          onComplete={onAnimationComplete} // Callback when animation completes
          style={{ width: "100%", height: "auto" }}
        />
      </div>
    </div>
  );
};

export default MyLottieAnimation;

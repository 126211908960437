import React from "react";
import { Box, Typography } from "@mui/material";
import CloudIcon from "../assets/case-type/Cloud.svg";
import NetworkIcon from "../assets/case-type/Network.svg";
import EndpointIcon from "../assets/case-type/Endpoint.svg";
import EmailIcon from "../assets/case-type/Email.svg";
import IdentityIcon from "../assets/case-type/Identity.svg";

interface AttackSurfaceDisplayProps {
  caseType: string;
}

const AttackSurfaceDisplay: React.FC<AttackSurfaceDisplayProps> = ({
  caseType,
}) => {
  const caseTypeIcons: Record<string, string> = {
    Cloud: CloudIcon,
    Network: NetworkIcon,
    Endpoint: EndpointIcon,
    Email: EmailIcon,
    Identity: IdentityIcon,
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <img
        src={caseTypeIcons[caseType] || caseTypeIcons["Cloud"]}
        alt={caseType}
        style={{ width: "1em", height: "1em", marginRight: "0.5em" }}
      />
      <Typography variant="body2">{caseType}</Typography>
    </Box>
  );
};

export default AttackSurfaceDisplay;

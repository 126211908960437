// App.tsx
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./config/theme";
import AppRoutes from "./routes/AppRoutes";
import { useDispatch } from "react-redux";
import { setAuthState } from "./features/auth/authSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const App = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated, getAccessTokenSilently, isLoading, error } =
    useAuth0();

  React.useEffect(() => {
    const fetchToken = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          dispatch(
            setAuthState({
              user: {
                name: user?.name || "",
                email: user?.email || "",
                picture: user?.picture || "",
              },
              token: token || "",
              isAuthenticated: true,
              loading: false,
              error: null,
            })
          );
        } catch (error: any) {
          console.error("Error fetching token: ", error);
          dispatch(
            setAuthState({
              user: null,
              token: null,
              isAuthenticated: false,
              loading: false,
              error: error.message,
            })
          );
        }
      } else if (!isLoading) {
        dispatch(
          setAuthState({
            user: null,
            token: null,
            isAuthenticated: false,
            loading: false,
            error: error ? error.message : null,
          })
        );
      }
    };

    fetchToken();
  }, [
    isAuthenticated,
    getAccessTokenSilently,
    dispatch,
    user,
    isLoading,
    error,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingAnimation from "./LoadingAnimation";

interface EmailImageSectionProps {
  emailImageUrl: string; // API endpoint to fetch the image
}

const EmailImageSection: React.FC<EmailImageSectionProps> = ({
  emailImageUrl,
}) => {
  const [imageData, setImageData] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchImage = async () => {
      try {
        setLoading(true);

        const token = await getAccessTokenSilently();
        const response = await fetch(emailImageUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch image. Status: ${response.status}`);
        }

        // Process the response as binary data
        const blob = await response.blob();
        const imageObjectURL = URL.createObjectURL(blob);
        setImageData(imageObjectURL);
      } catch (err: any) {
        setError(err.message || "An error occurred while fetching the image");
        console.error("Error fetching image:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchImage();

    // Cleanup the object URL when the component unmounts
    return () => {
      if (imageData) {
        URL.revokeObjectURL(imageData);
      }
    };
  }, [emailImageUrl]);

  if (loading) {
    return (
      <div style={{ width: "100%", height: "50vh" }}>
        <LoadingAnimation />
      </div>
    );
  }

  if (error) {
    return <Box>{error}</Box>;
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: "8px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
      }}
    >
      {imageData ? (
        <img
          src={imageData}
          alt="Email Snapshot"
          style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: "8px" }}
        />
      ) : (
        <Box>Error loading image</Box>
      )}
    </Box>
  );
};

export default EmailImageSection;

import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../src/app/rootReducer";
import { Case } from "../../src/types/caseTypes";
import { useAppDispatch } from "../app/hooks";
import { fetchCases } from "../../src/api/casesApi";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSkeleton from "../components/LoadingSkeleton";
import ErrorDisplay from "../components/ErrorDisplay";
import TopBar from "../components/TopBar";
import FiltersSection from "../components/FiltersSection";
import CasesTable from "../components/CasesTable";
import PaginationControls from "../components/PaginationControls";
import dayjs from "dayjs";

const CasesListPage = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  // Redux store data
  const cases = useSelector((state: RootState) => state.cases.cases);
  const loading = useSelector((state: RootState) => state.cases.loading);
  const error = useSelector((state: RootState) => state.cases.error);

  // Local state
  const [filteredCases, setFilteredCases] = useState<Case[]>([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 15;
  const [order, setOrder] = useState<"asc" | "desc" | undefined>(undefined);
  const [orderBy, setOrderBy] = useState<keyof Case | undefined>(undefined);
  const [activeFilter, setActiveFilter] = useState("Escalated");
  const [searchTerm, setSearchTerm] = useState("");

  // State for advanced filters
  const [advancedFilters, setAdvancedFilters] = useState<any>(null);

  // Fetch cases on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(fetchCases(token));
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };
    fetchData();
  }, [dispatch, getAccessTokenSilently]);

  // Update filtered cases whenever cases, activeFilter, searchTerm, or advancedFilters change
  useEffect(() => {
    filterCases();
  }, [cases, activeFilter, searchTerm, advancedFilters]);

  // This function applies activeFilter, searchTerm, and advanced filters to filter the cases
  const filterCases = () => {
    let filteredData = [...cases];

    // Apply advanced filters first if they exist
    if (advancedFilters) {
      filteredData = applyAdvancedFilters(filteredData, advancedFilters);
    }

    // Apply activeFilter logic if advancedFilters is not applied
    if (!advancedFilters) {
      if (activeFilter === "Escalated") {
        filteredData = filteredData.filter(
          (caseItem) =>
            caseItem.status !== "Auto Closed" &&
            caseItem.conclusion !== "Benign"
        );
      } else if (activeFilter === "Benign") {
        filteredData = filteredData.filter(
          (caseItem) =>
            caseItem.status === "Auto Closed" ||
            caseItem.conclusion === "Benign"
        );
      }
      // If "all", we do not filter by conclusion or status
    }

    // Apply searchTerm logic
    if (searchTerm.trim() !== "") {
      const term = searchTerm.toLowerCase();
      filteredData = filteredData.filter((caseItem) => {
        return Object.values(caseItem).some((val) =>
          val?.toString().toLowerCase().includes(term)
        );
      });
    }

    setFilteredCases(filteredData);
  };

  // Extract unique filter values from cases data
  const uniqueConclusions = Array.from(
    new Set(cases.map((c) => c.conclusion).filter(Boolean))
  );
  const uniqueStatuses = Array.from(
    new Set(cases.map((c) => c.status).filter(Boolean))
  );
  const uniqueSeverities = Array.from(
    new Set(cases.map((c) => c.severity).filter(Boolean))
  );

  // Apply advanced filters and update state
  const handleApplyFilters = (filters: any) => {
    setSearchTerm(""); // Clear search term
    setActiveFilter("all"); // Switch to "all" filter
    setAdvancedFilters(filters); // Set the advanced filters so they are applied in filterCases
  };

  // Function to apply advanced filters to the cases array
  const applyAdvancedFilters = (
    casesToFilter: Case[],
    filters: any
  ): Case[] => {
    const {
      conclusions,
      statuses,
      severities,
      createdAt,
      alertTimestamp,
      identities,
    } = filters;

    let filtered = [...casesToFilter];

    // Filter by conclusion
    if (conclusions.length > 0) {
      filtered = filtered.filter((caseItem) => {
        return conclusions.includes(caseItem.conclusion);
      });
    }

    // Filter by status
    if (statuses.length > 0) {
      filtered = filtered.filter((caseItem) => {
        return statuses.includes(caseItem.status);
      });
    }

    // Filter by severity
    if (severities.length > 0) {
      filtered = filtered.filter((caseItem) => {
        return severities.includes(caseItem.severity);
      });
    }

    // Created At Filter
    if (createdAt[0] || createdAt[1]) {
      filtered = filtered.filter((caseItem) => {
        const caseDate = dayjs(caseItem.createdAt);
        const [start, end] = createdAt;
        if (start && caseDate.isBefore(dayjs(start), "day")) {
          return false;
        }
        if (end && caseDate.isAfter(dayjs(end), "day")) {
          return false;
        }
        return true;
      });
    }

    // Alert Timestamp Filter
    if (alertTimestamp[0] || alertTimestamp[1]) {
      filtered = filtered.filter((caseItem) => {
        if (!caseItem.alertTimestamp) {
          return false;
        }
        const alertDate = dayjs(caseItem.alertTimestamp);
        const [alertStart, alertEnd] = alertTimestamp;
        if (alertStart && alertDate.isBefore(dayjs(alertStart), "day")) {
          return false;
        }
        if (alertEnd && alertDate.isAfter(dayjs(alertEnd), "day")) {
          return false;
        }
        return true;
      });
    }

    // Identity Filter
    if (identities.length > 0) {
      filtered = filtered.filter((caseItem) => {
        const caseIdentity = caseItem.identity
          ? caseItem.identity.toLowerCase()
          : "";
        return identities.some((identity: string) =>
          caseIdentity.includes(identity.toLowerCase())
        );
      });
    }

    return filtered;
  };

  // Clear advanced filters
  const handleClearAdvancedFilters = () => {
    setAdvancedFilters(null);
    // Possibly reset activeFilter to default if needed
    setActiveFilter("Escalated");
    setSearchTerm("");
  };

  // Handle loading and error states
  if (loading) return <LoadingSkeleton />;
  if (error) return <ErrorDisplay error={error} />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        width: "100%",
      }}
    >
      {/* Top Bar */}
      <TopBar totalCases={cases.length} />

      {/* Filters and Search */}
      <FiltersSection
        cases={cases}
        setFilteredCases={setFilteredCases}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        uniqueConclusions={uniqueConclusions}
        uniqueStatuses={uniqueStatuses}
        uniqueSeverities={uniqueSeverities}
        applyFilters={handleApplyFilters}
      />

      {/* Cases Table */}
      <CasesTable
        cases={filteredCases}
        page={page}
        rowsPerPage={rowsPerPage}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
      />

      {/* Pagination Controls */}
      <PaginationControls
        totalCases={filteredCases.length}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
      />
    </Box>
  );
};

export default CasesListPage;

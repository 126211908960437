import React from "react";
import { Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface ConclusionIndicatorProps {
  conclusion: "Benign" | "Suspicious" | "Malicious";
}

const ConclusionIndicator: React.FC<ConclusionIndicatorProps> = ({
  conclusion,
}) => {
  const theme = useTheme();

  const conclusionStyles: Record<
    ConclusionIndicatorProps["conclusion"],
    { backgroundColor: string; color: string }
  > = {
    Benign: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.main,
    },
    Suspicious: {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.info.main,
    },
    Malicious: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
    },
  };

  return (
    <Chip
      label={conclusion}
      size="small"
      sx={{
        backgroundColor: conclusionStyles[conclusion].backgroundColor,
        color: conclusionStyles[conclusion].color,
        borderRadius: "8px",
        paddingX: "8px",
        fontSize: "0.75rem",
        fontWeight: "bold",
        height: "24px",
        minWidth: "80px",
        maxWidth: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: `1px solid ${conclusionStyles[conclusion].color}`,
      }}
    />
  );
};

export default ConclusionIndicator;
